/* eslint-disable react/prop-types */
import React from 'react'
import Button from '@material-ui/core/Button'
import DevicesOtherIcon from '@material-ui/icons/DevicesOther'
import strings from '../../../strings/Strings'
import { getSingleTicket } from '../../../../APICall/call'
import { CircularProgress } from '@material-ui/core'

export default function RedirectToDevice (props) {
  const [isLoading, setIsLoading] = React.useState(false)

  const renderDeviceSettingsView = () => {
    setIsLoading(true)
    getSingleTicket(props.ticketId)
      .then(res => {
        console.debug('RES USER FOR REDIRECT ', res)
        setIsLoading(false)
        props.redirectToDevice({ email: res.user.email, user_id: res.user.id })
      })
      .catch(err => {
        setIsLoading(false)
        return alert('Error retrieving ticket extra data: ' + (err.message || err.response || err.status || err))
      })
  }
  return (
    isLoading
      ? <CircularProgress size={24} style={{ position: 'relative', top: 4 }} />
      : <Button
        variant="outlined"
        style={{ margin: '0rem', color: '#4CAF50' }}
        onClick={() => { renderDeviceSettingsView() }}
        startIcon={<DevicesOtherIcon />}>
        {strings.dataTableToolbarCustomStrings.redirectToDevice}
      </Button>
  )
}
