/* eslint-disable react/prop-types */
/* eslint-disable no-multi-spaces */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import strings from '../../strings/Strings'

const useStyles = makeStyles(theme => ({
  container : {
    display  : 'flex',
    flexWrap : 'wrap'
  },
  formControl : {
    margin   : theme.spacing(1),
    minWidth : '10rem'
  }
}))

/* Component that takes care of setting the "Failed" status
by selecting one of the reasons present in the list */

export default function FailedAlert (props) {
  const classes             = useStyles()
  const [open, setOpen]     = React.useState(false)
  const [reason, setReason] = React.useState(strings.installerStatusUpdate.failedReasons.r1)

  const handleChange = event => {
    setReason(event.target.value)
  }

  /* Handle confirm button
    Output: @param reason => Reason Callback to the InstallerViewStatusUpdate */
  const handleConfirm = () => {
    props.reasonCallback(reason)
    setOpen(false)
  }

  return (
    <>
      <Button
        disabled={props.isDisabled}
        variant="outlined" color="primary" onClick={() => { setOpen(true) }}
        style={{ margin: '0.5rem' }}>
        {strings.dataTableToolbarCustomStrings.failed}
      </Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={() => { setOpen(false) }}>
        <DialogTitle>{strings.installerStatusUpdate.selectReason}</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">{strings.installerStatusUpdate.reason}</InputLabel>
              <Select
                native
                value={reason}
                onChange={handleChange}
              >
                {Object.values(strings.installerStatusUpdate.failedReasons).map((value, index) => {
                  return <option key={index} value={value}>{value}</option>
                })}
              </Select>
            </FormControl>

          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpen(false) }} color="primary">
            {strings.groheView.cancel}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {strings.groheView.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
