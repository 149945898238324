import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import strings from '../../strings/Strings';
import { getSingleTicket } from '../../../APICall/call';
import {LinearProgress } from '@material-ui/core'
/* JSX component that shows all the information regarding a ticket  */


const styles = {
    h2: {
        marginBottom: "0.5rem",
        background: "linear-gradient(90deg, rgba(17,32,56,1) 0%, rgba(17,32,56,0.908000700280112) 100%)",
        color: "white",
        padding: "0",
        paddingLeft: "0.4rem",
        borderRadius: "4px"
    }
}

class TicketExtraInfoRow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            extraData: null,
            isLoading: false,
            isError: null
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        getSingleTicket(this.props.ticketId)
            .then(res => {
                this.setState({ extraData: res, isLoading:false })
                return Promise.resolve()
            })
            .catch(err => {
                console.error("Errore retrieving ticket extra data: " + (err.message || err.response || err.status || err));
                this.setState({ extraData: null, isLoading: false, isError: "" + err });
                return Promise.reject()
            })
    }

    render() {
        return (
            this.state.extraData
                ? (this.extraInfoLabels())
                : this.state.isError
                    ? <div><p><h5>{this.state.isError}</h5></p></div>
                    : <div> <LinearProgress size={24} 
                     /></div>
        );
    }

    extraInfoLabels = () => {
        return (
            <div>
                <div style={{ padding: "1rem", background: "#FAFAFA" }}>
                    <Grid container style={{ background: "#FAFAFA" }}>
                        <Grid item xs={12}>
                            <h2 style={styles.h2}>{strings.dataTableParams.ticket}</h2>
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.ticketId}:</h4> {this.state.extraData.id}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.status}:</h4> {this.state.extraData.status}
                        </Grid>
                        <Grid item xs={12}>
                            <h2 style={styles.h2}>{strings.dataTableParams.insurance}</h2>
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.insuranceId}:</h4> {this.state.extraData.insurance.id}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.insuranceName}:</h4> {this.state.extraData.insurance.name}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.insurancePolicyId}:</h4> {this.state.extraData.insurance.insurance_policy_id}
                            {/* </p> */}
                        </Grid>
                        <Grid item xs={12}>
                            <h2 style={styles.h2}>{strings.dataTableParams.location}</h2>
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.locationId}:</h4> {this.state.extraData.location.id}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.name}:</h4> {this.state.extraData.location.name}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.groheView.zipCode}:</h4> {this.state.extraData.location.zipcode}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.groheView.city}:</h4> {this.state.extraData.location.city}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.street}:</h4> {this.state.extraData.location.street}
                        </Grid>
                        <Grid item xs={12}>
                            <h2 style={styles.h2}>{strings.dataTableParams.user}</h2>
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.groheView.userId}:</h4> {this.state.extraData.user.id}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.groheView.email}:</h4>{this.state.extraData.user.email}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.firstName}:</h4> {this.state.extraData.user.firstName}
                        </Grid>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0" }}>{strings.dataTableParams.lastName}:</h4> {this.state.extraData.user.lastName}
                        </Grid>
                        {this.state.extraData.installer 
                        ?
                            <>
                                <Grid item xs={12}>
                                    <h2 style={styles.h2}>{strings.dataTableParams.installer}</h2>
                                </Grid>
                                <Grid item xs={4}>
                                    <h4 style={{ margin: "0" }}>{strings.dataTableParams.installerId}:</h4> {this.state.extraData.installer.id}
                                </Grid>
                                <Grid item xs={4}>
                                    <h4 style={{ margin: "0" }}>{strings.dataTableParams.installerEmail}:</h4> {this.state.extraData.installer.email}
                                </Grid>
                                {/* <Grid item xs={4}>
                                        <h4 style={{ margin: "0" }}>{strings.dataTableParams.installerCity}:</h4> {this.state.extraData.installer.city}
                                    </Grid> */}
                                {/* <Grid item xs={4}>
                                        <h4 style={{ margin: "0" }}>{strings.dataTableParams.installerAddress1}:</h4> {this.state.extraData.installer.address1}
                                    </Grid> */}
                                <Grid item xs={4}>
                                    <h4 style={{ margin: "0" }}>{strings.dataTableParams.zipCode2}:</h4> {this.state.extraData.installer.zipcode}
                                </Grid>
                            </>
                            : null}
                    </Grid>

                </div>
            </div>
        )
    }
}

export default TicketExtraInfoRow