/* eslint-disable no-multi-spaces */
import React from 'react'
import permissions from '../../GlobalValues/PermissionKeys'

/* Return the user badge */
export default function UserBadge (props) {
  const userRole    = localStorage.getItem(permissions.perms.role)
  const badgeParams = {
    grohe_admin : ['#112038', 'GROHE Admin'],
    installer   : ['#6c757d', 'Installer'],
    insurer     : ['#007bff', 'Insurer']
  }
  return (
    <>
      <div style={{
        backgroundColor : badgeParams[userRole][0],
        textAlign       : 'center',
        margin          : 0,
        paddingTop      : '0.15rem',
        paddingRight    : '0.4rem',
        paddingLeft     : '0.4rem',
        paddingBottom   : '0.15rem',
        display         : 'inline-table',
        alignSelf       : 'center',
        borderRadius    : '4px'
      }}>
        <h4 style={{ margin: 0, padding: 0, textAlign: 'center' }}>
          {badgeParams[userRole][1]}
        </h4>
      </div>
    </>
  )
}
