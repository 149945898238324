/* eslint-disable react/prop-types */
import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

/* Component for the expandable Rows on the table,
to show more data about the ticket
Input: props */

export default function ExpandedRowDetailsView (props) {
  return (
    <TableRow style={{ background: 'white' }}>
      <TableCell style={{ color: '#112038', padding: '0' }}
        colSpan={props.rowDataLength}>
        {props.extraData}
      </TableCell>
    </TableRow>
  )
}
