/* eslint-disable react/prop-types */
/* eslint-disable no-multi-spaces */
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import strings from '../../../strings/Strings'
import { Grid, Typography, IconButton, CircularProgress, Tooltip } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import SearchResultComponent from '../../InstallerModalComponents/SearchResult'
import SearchIcon from 'material-react-icons/Search'
import states from '../../GlobalValues/PreviousStatusCheck'
import { getInstallers, patchTicketBatch } from '../../../../APICall/call'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import NoResultFound from '../../../../Assets/img/noresultfound.png'

/* Component that represents the main modal box for finding the installer.
This contains the text fields for the filters and the SearchResult */

class InstallerModalBox extends Component {

  constructor(props) {
    super(props)
    this.state = {
      installers: null,
      cachedInstallers: null,
      filters: {
        zipCode: '',
        name: ''
      },
      isLoading: false,
      open: false,
      isVoid: false
    }
  }

  /* Method that call getInstallers method from APICall/call
  and set it to the state with related front-end parameteres */
  callGetInstallers = () => {
    getInstallers()
      .then(res => {
        this.setState({ installers: res, cachedInstallers: res, isLoading: false, isVoid: (!res.installers || res.installers.length === 0) })
        Promise.resolve()
      })
      .catch(err => {
        alert(strings.error.somethinWentWrong + (err.message || err.status || err.response));
        this.setState({ open: false, installers: null, cachedInstallers: null, isLoading: false })
        return Promise.reject(err)
      })
  }

  /* Handle open dialog */
  handleOpen = () => {
    this.setState({ isLoading: true, open: true })
    this.callGetInstallers()
  }

  /* Handle close dialog */
  handleClose = () => {
    this.setState({ open: false })
  }

  /* Search view update with filtered data, else no result found */
  updateInstallersByFilter = () => {
    if (this.state.filters.zipCode === '' &&
      this.state.filters.name === '') {
      this.setState({ isLoading: true, installers: null, cachedInstallers: null })
      this.callGetInstallers()
    } else {
      let installers = { installers: this.state.cachedInstallers.installers }
      let filtered_installers = {
        installers: installers.installers.filter((installer) => {
          return (
            (installer.zipcode === this.state.filters.zipCode && installer.name === this.state.filters.name) ||
            (installer.zipcode === this.state.filters.zipCode && this.state.filters.name === '') ||
            (installer.name === this.state.filters.name && this.state.filters.zipCode === ''))
        })
      }
      this.setState({ installers: filtered_installers, isVoid: (!filtered_installers.installers || filtered_installers.installers.length === 0) })
    }
  }

  /* OnChangeFilter event that set new filters*/
  onChangeFilters = (event) => {
    let newFilters = this.state.filters
    newFilters[event.target.id] = event.target.value
    this.setState({ filters: newFilters })
  }

  /* Return the No result found Image inside a Paper */
  noResultFoundComponent = () => {
    return (
      <>
        <Paper>
          <img src={NoResultFound}
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        </Paper>
      </>
    )
  }

  /* Actual assignment of the installer [PATCH]
  Input: Installer object to assign on tickets */

  assignInstaller = (installer) => {
    const ticketsToPatch = this.props.ticketSelected.map((ticket) => {
      return {
        ticket_id    : ticket[0],
        status       : strings.status_type.assigned.toLowerCase(),
        installer_id : installer.installer_id
      }
    })
    patchTicketBatch(ticketsToPatch)
      .then(res => {
        if (res.data.failed &&
          res.data.failed.length > 0) {
          return Promise.reject(strings.errors.errorPatchTicket + res.data.failed)
        }
        this.handleClose()
        this.props.canRefresh(true)
        return Promise.resolve()
      })
      .catch(err => {
        alert(strings.errors.somethinWentWrong + (err || err.message || err.response || err.status));
        return Promise.reject(err)
      })
  }

  /* Set button "Assign all selected" enabled if the previous status of all tickets 
  selected is compatible with the new one */
  setRightButtonsEnabled = (status) => {
    const isDisabled = this.props.ticketSelected
      .filter((element) => {
        return status.previous_states
          .includes(element[element.length - 1]) === false
      })
      .length > 0
    return isDisabled
  }

  render() {
    return (
      <div>
        <Button
          disabled={this.setRightButtonsEnabled(states.allowed_status_update.role.grohe_admin.status.assigned)}
          variant="outlined" color="primary" onClick={() => { this.handleOpen() }} style={{ margin: '1rem' }}
          startIcon={<AssignmentIndIcon />}
        >
          {strings.dataTableToolbarCustomStrings.assignInstaller}
        </Button>

        <Dialog
          keepMounted={false}
          open={this.state.open}
          onClose={() => { this.handleClose() }}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="draggable-dialog-title" style={{ backgroundColor: '#112038' }}>
            <Typography
              variant="h4"
              style={{ fontFamily: 'url(../../Assets/fonts/LinotypeUnivers-630Bold.ttf)', color: 'white' }}>
              {strings.groheView.installerModalTitle}
            </Typography>

          </DialogTitle>
          <DialogContent>

            <Grid container >
              <Grid item xs={5}>
                <TextField
                  id="zipCode"
                  label={strings.groheView.zipCode}
                  type="number"
                  style={{ padding: '0.5rem' }}
                  variant="outlined"
                  value={this.state.filters.zipCode !== '' ? this.state.filters.zipCode : ''}
                  onChange={(e) => { this.onChangeFilters(e) }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="name"
                  label={strings.groheView.instName}
                  type="text"
                  style={{ padding: '0.5rem' }}
                  variant="outlined"
                  value={this.state.filters.name !== '' ? this.state.filters.name : ''}
                  onChange={(e) => { this.onChangeFilters(e) }}
                />
              </Grid>

              <Grid item xs={2}>
                <Tooltip title={strings.installerModalBox.applyFilters}>
                  <IconButton onClick={() => { this.updateInstallersByFilter() }}>
                    <SearchIcon color="#112038" />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12} component="span">
                {/* If is loading or installers === null => Circular Progress
                else check there are no result from filters from isVoid bool 
                and show no result Found image
                otherwise show results */}
                {this.state.isLoading || !this.state.installers
                  ? <CircularProgress size={24} style={{ position: 'relative', top: 4, float: 'left' }} />
                  : this.state.isVoid
                    ? this.noResultFoundComponent()
                    : <Paper style={{ border: '1px solid rgb(17,32,56)' }}>
                      <SearchResultComponent
                        certifiedInstallers={this.state.installers}
                        installerAssigned={this.assignInstaller} />
                    </Paper>
                }
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => { this.handleClose() }} color="primary">
              {strings.groheView.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
export default InstallerModalBox
