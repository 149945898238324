import React from 'react'
import Dashboard from './components/Home/MainComponents/Dashboard'
import strings from './components/strings/Strings'

/* Main Function that initialize the Dashboard component */

function App (props) {
  return (
    <div> {/* Rendering by section helps for debug and future improvements
    because there are not any routing logic */}
    <Dashboard section={strings.groheView.dashboard} />
    </div>
  )
}
export default App
