/* eslint-disable react/prop-types */
import React from 'react'
import NoResultFoundImg from '../../../../Assets/img/noresultfound.png'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import NewUserComponent from './SubComponents/NewUserComponent'

const useStyles = makeStyles({
  media : {
    height : '8rem'
  }
})

/* Function that rapresent the constructor of a SearchResult component
returns a card with the New User Components or an image that indicate "no result found" */
export default function SearchResult (props) {
  const classes = useStyles()

  /* Construct the component in function of the result from backend (NEED CHANGES) */
  const getSearchResults = () => {
    switch (props.results) {
      case '404': // Not found status code
        return (
          <img src={NoResultFoundImg}
            style={{
              maxWidth  : '100%',
              maxHeight : '100%'
            }}
          />)
      default:
        return (<>
          <NewUserComponent
            userInfo={props.results}
            refreshTable={() => { props.refreshTable() }} />
        </>)
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent style={{ backgroundColor: '#FAFAFA' }}>
        {getSearchResults()}
      </CardContent>
    </Card>
  )
}
