import React from 'react'
import 'react-flags-select/css/react-flags-select.css'
import strings from '../../../strings/Strings.js'
import translations from '../../../strings/translations.json'
import LanguageIcon from '@material-ui/icons/Language'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { InputAdornment } from '@material-ui/core'

export default function LanguageSelect () {
  /* Language selection handler */
  const onSelectLanguage = (event) => {
    const newLanguage = event.target.value
    strings.setLanguage(newLanguage)
    localStorage.setItem('language', newLanguage)
    window.location.reload()
  }

  return (
    <>
      <Select
        disableUnderline
        name={'language'}
        value={strings.getLanguage()}
        onChange={onSelectLanguage}
        startAdornment={
          <InputAdornment>
            <LanguageIcon style={{ margin: '0.5rem' }} />
          </InputAdornment>
        }
      >
        {/* Map availableLanguage Key and related value to MenuItem component */}
        {
          strings.getAvailableLanguages()
            .map((languageKey) => {
              return (<MenuItem
                key='language'
                value={languageKey}>
                {translations[languageKey].language}
              </MenuItem>)
            })

        }
      </Select>
    </>
  )
}
