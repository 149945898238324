/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import statisticsImage from '../../../Assets/img/statistics.png'
import GuardsImage from '../../../Assets/img/guards.jpg'
import SensesImage from '../../../Assets/img/senses.jpg'
import Grid from '@material-ui/core/Grid'
import { CircularProgress, Paper } from '@material-ui/core'
import { getStatistics } from '../../../APICall/call'
import strings from '../../strings/Strings'
import MUIDataTable from 'mui-datatables'
import installerRateImg from '../../../Assets/img/installerRate.jpg'
import totalInstImg from '../../../Assets/img/totalInst.png'
import role from '../GlobalValues/AuthenticationRole'
import moment from 'moment'
import userRole from '../GlobalValues/AuthenticationRole'
import permissions from '../GlobalValues/PermissionKeys'
import {
  Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, ComposedChart
} from 'recharts';
import TextField from '@material-ui/core/TextField'



/* Return the Circle View Object with the related text/number centered in */
const Circle = (props) => {
  const circleStyle = {
    display: 'flex',
    border: '2px solid #112038',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    borderStyle: '2px solid red',
    width: '8rem',
    height: '8rem',
    margin: 'auto'

  }

  const valueStyle = {
    fontFamily: 'Roboto',
    position: 'relative',
    color: '#112038',
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <div style={circleStyle}>
      <Typography variant="h6" style={valueStyle}>
        {props.value}
      </Typography>
    </div>
  )
}

const muiDatatableTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        minHeight: '10rem',
        maxHeight: '10rem',
        overflowX: 'scroll',
        overflowY: 'scroll'
      }
    }
    ,
    MUIDataTableFilterList: {
      chip: {
        display: 'none'
      }
    }
  }
})

const statsImages = [totalInstImg, installerRateImg, GuardsImage, SensesImage]
const MAX_DATE_RANGE_IN_DAYS = 90 // range limit in days for installations per day
const rowNumber = 4

class Statistics extends Component {
  constructor(props) {
    super(props)

    /* Initialize default dates:
    Date From : 30 days before,
    Date To: Current date */
    let currentDate = moment().format('YYYY-MM-DD')
    let thirtyDaysAgo = moment().subtract(30, 'day').format('YYYY-MM-DD')

    this.state = {
      isLoading: true,
      isChartDataLoading: false,
      statisticsData: null,
      windowWidth: window.innerWidth - 100,
      chartData: null,
      chartDateFrom: thirtyDaysAgo,
      chartDateTo: currentDate,
      isPossibleToApplyDateRange: true,
      isError: false
    }

  }

  /* Get all statistics data */
  componentDidMount() {
    window.addEventListener('resize', this.changeWindowSizeHandler);
    const queryParams = {}
    return getStatistics(queryParams)
      .then(res => {
        this.setState({ statisticsData: res, isLoading: false, chartData: this.getChartData(res), isError: false })
        return
      })
      .catch(err => {
        console.error(err)
        this.setState({ isLoading: false, isError: true })
        return alert('Statistics ' + (err.message || err.response || err.status || err))
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeWindowSizeHandler)
  }

  changeWindowSizeHandler = () => {
    this.setState({ windowWidth: window.innerWidth - 100 })
  }

  /* Get all dates between a range in format YYYY-MM-DD */
  getDatesBetween = (startDate, stopDate) => {
    let dateArray = [];
    let currentDate = moment(startDate);
    let fromDate = moment(stopDate);
    while (currentDate <= fromDate) {
      dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  /* Get chart data */
  getChartData = (statisticsData) => {
    let data = statisticsData.installationsPerDay.days
    let dates = this.getDatesBetween(this.state.chartDateFrom, this.state.chartDateTo)
      .map((date) => {
        return {
          day: date,
          total: 0
        }
      })
    for (let date of data) {
      dates[dates.findIndex((el) => { return el.day === date.day })] = date
    }
    return dates
  }

  /* Get chart data in range [from, to] */
  getChartDataFromTo(from, to) {
    const queryParams = {}
    queryParams['from'] = from
    queryParams['to'] = to
    this.setState({ isChartDataLoading: true })
    return getStatistics(queryParams)
      .then(res => {
        this.setState({ isChartDataLoading: false, chartData: this.getChartData(res), isError: false })
        return
      })
      .catch(err => {
        console.error(err)
        this.setState({ isChartDataLoading: false, isError: true })
        return alert('Statistics ' + (err.message || err.response || err.status || err))

      })
  }

  /* Get Rows data for both tables from statisticsData JSON */
  getTableData = (userRole) => {
    let tableData = []
    switch (userRole) {
      case role.auth.insurance:

        for (let insurer of this.state.statisticsData.installationsByInsurer) {
          tableData.push([
            insurer.name,
            ...Object.values(insurer.status)
          ])
        }
        return tableData
      case role.auth.installer:
        for (let installer of this.state.statisticsData.installationsByInstallers) {
          let row = [
            installer.name,
            installer.zipcode,
            ...Object.values(installer.installations)
          ]
          tableData.push(row)
        }
        return tableData
    }
  }

  /* Get numeric statistics */
  getStats = () => {
    return [
      [strings.statisticsView.totalInstallations, this.state.statisticsData.totalInstallations.total],
      [strings.statisticsView.instRatePerDay, (this.state.statisticsData.installationsPerDay.rate * 100) + "%"],
      [strings.statisticsView.guards, this.state.statisticsData.totalInstallations.guard],
      [strings.statisticsView.senses, this.state.statisticsData.totalInstallations.sense]
    ]
  }

  /* Return Table composition for both statistics tables 
  Title, columns & data*/
  getTableStats = () => {
    return ([{
      title: strings.statisticsView.installationsByInsurer,
      columns: [
        { name: strings.dataTableParams.insuranceName, options: { display: localStorage.getItem(permissions.perms.role) === userRole.auth.grohe } },
        { name: strings.status_type.open, options: { display: true } },
        { name: strings.status_type.approved, options: { display: true } },
        { name: strings.status_type.assigned, options: { display: true } },
        { name: strings.status_type.appointment, options: { display: true } },
        { name: strings.status_type.underInvestigation, options: { display: true } },
        { name: strings.status_type.installed, options: { display: true } },
        { name: strings.status_type.checked, options: { display: true } },
        { name: strings.status_type.canceled, options: { display: true } }
      ],
      records: this.getTableData(role.auth.insurance)
    }, {
      title: strings.statisticsView.installationsByInstaller,
      columns: [
        strings.statisticsView.installerName,
        strings.dataTableParams.zipCode2,
        { name: strings.status_type.appointment, options: { display: true } },
        { name: strings.status_type.underInvestigation, options: { display: true } },
        { name: strings.status_type.installed, options: { display: true } },
        { name: strings.status_type.checked, options: { display: true } },
        { name: strings.status_type.canceled, options: { display: true } },
        { name: strings.status_type.assigned, options: { display: true } }],
      records: this.getTableData(role.auth.installer)
    }])
  }

  /* Change Date event handler */
  handleChange = (event) => {
    let isPossibleToApplyDateRange = false
    let dateTo = null
    let dateFrom = null
    let diff = null

    switch (event.target.id) {
      case "dateFrom":
        dateTo = moment(this.state.chartDateTo)
        dateFrom = moment(event.target.value)
        diff = dateTo.diff(dateFrom, "days")
        isPossibleToApplyDateRange = (diff <= MAX_DATE_RANGE_IN_DAYS) && diff >= 0
        this.setState({
          chartDateFrom: event.target.value,
          isPossibleToApplyDateRange: isPossibleToApplyDateRange
        })
        if (isPossibleToApplyDateRange) {
          this.getChartDataFromTo(event.target.value, this.state.chartDateTo)
        }
        break;
      case "dateTo":
        dateTo = moment(event.target.value)
        dateFrom = moment(this.state.chartDateFrom)
        diff = dateTo.diff(dateFrom, "days")
        isPossibleToApplyDateRange = (diff <= MAX_DATE_RANGE_IN_DAYS)
          && diff >= 0
          && dateTo <= moment() // dateTo is not greater than 'now
        this.setState({
          chartDateTo: event.target.value,
          isPossibleToApplyDateRange: isPossibleToApplyDateRange
        })
        if (isPossibleToApplyDateRange) {
          this.getChartDataFromTo(this.state.chartDateFrom, event.target.value)
        }
        break;
    }
  }



  render() {
    const options = [
      {
        download: false,
        print: false,
        search: false,
        filter: false,
        selectableRows: false,
        rowsPerPage: 1,
        rowsPerPageOptions: [1],
        customFooter: function (count, page, rowsPerPage, changeRowsPerPage, changePage, ) {
          return (<></>)
        }
      },
      {
        filterType: 'textField',
        download: false,
        print: false,
        search: true,
        filter: true,
        rowsPerPage: rowNumber,
        rowsPerPageOptions: [rowNumber],
        selectableRows: false,

      }
    ]



    return (
      this.state.isLoading
        ? <CircularProgress size={64} style={{ position: 'relative', top: 4, float: "left", color: "red" }} />
        : this.state.isError ?
          <p>{strings.errors.errosInStatistics}</p>
          : <Grid container spacing={2} >
            {this.getTableStats().map((value, index) => {
              return (
                <Grid key={index} item xs={6}>
                  <Card >
                    <CardMedia
                      style={{ height: '8rem' }}
                      image={statisticsImage}
                    />
                    <CardContent>
                      <MuiThemeProvider theme={muiDatatableTheme}>
                        <MUIDataTable
                          title={value.title}
                          data={value.records}
                          columns={value.columns}
                          options={options[index]}
                          responsive={'scroll'}
                        />
                      </MuiThemeProvider>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}

            {this.getStats().map((value, index) => {
              return (
                <Grid key={index} item xs={3}>
                  <Card >
                    <CardMedia
                      style={{ height: '8rem' }}
                      image={statsImages[index]}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        {value[0]}
                      </Typography>
                      <Circle value={value[1]} />

                    </CardContent>
                  </Card>
                </Grid>
              )
            })}

            <Card style={{ marginTop: "1rem" }}>
              <CardMedia
                style={{ height: "auto" }}>
                <Grid container direction='column'>
                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h6" style={{ margin: "1rem", color: "#112038" }}>
                      {strings.statisticsView.installationsPerDay}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h6"
                      component={'h6'}
                      style={{
                        margin: "1rem",
                        color: "#112038",
                        fontSize: '1rem'
                      }}>
                      {strings.tooltips.installationPerDayTooltip}
                    </Typography>
                  </Grid>
                </Grid>

                {this.state.isChartDataLoading
                  ? <CircularProgress size={64} style={{ position: 'relative', top: 4, float: "left", color: "red" }} />
                  : this.state.isError ?
                    <p>{strings.errors.errosInStatistics}</p>
                    : <>
                      <TextField
                        id="dateFrom"
                        label={this.state.isPossibleToApplyDateRange
                          ? strings.statisticsView.from
                          : <h4 style={{ color: 'red', margin: 0 }}>{strings.errors.invalidDateRange}</h4>}
                        type="date"
                        defaultValue={this.state.chartDateFrom}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={event => this.handleChange(event)}
                        style={{ margin: "1rem" }}
                      />

                      <TextField
                        id="dateTo"
                        label={this.state.isPossibleToApplyDateRange
                          ? strings.statisticsView.to
                          : <h4 style={{ color: 'red', margin: 0 }}>{strings.errors.invalidDateRange}</h4>}
                        type="date"
                        defaultValue={this.state.chartDateTo}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={event => this.handleChange(event)}
                        style={{ margin: "1rem" }}
                      />
                    </>}
              </CardMedia>
              <CardContent>
                <ComposedChart
                  width={this.state.windowWidth}
                  height={400}
                  data={this.state.chartData}
                  margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="total" stackId="instPd" fill="#8884d8" />
                  <Line type="monotone" dataKey="total" stroke="#ff7300" />
                </ComposedChart>
              </CardContent>
            </Card>
          </Grid>
    )
  }
}
export default Statistics
