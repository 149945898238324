/* eslint-disable react/prop-types */
/* eslint-disable no-multi-spaces */
import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import userImg from '../../../../../Assets/img/user.png'
import Avatar from '@material-ui/core/Avatar'
import strings from '../../../../strings/Strings'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { CircularProgress, InputAdornment } from '@material-ui/core'
import { getInstallers, addNewUser, getPolicies } from '../../../../../APICall/call'
import BuildIcon from '@material-ui/icons/Build'
import BusinessIcon from '@material-ui/icons/Business'

const userRole = {
  grohe_admin : 'grohe_admin',
  installer   : 'installer',
  insurer     : 'insurer'
}

/* Function that rapresent the modal component to create and add a new User */
export default function NewUserComponent (props) {
  const [role, setRole]                             = React.useState('')
  const [insuranceId, setInsuranceId]               = React.useState('')
  const [insuranceName, setInsuranceName]           = React.useState('')
  const [insurances, setInsurances]                 = React.useState(null)
  const [installerCompany, setInstallerCompany]     = React.useState('')
  const [installerCompanyId, setInstallerCompanyId] = React.useState('')
  const [installers, setInstallers]                 = React.useState(null)
  const [isLoading, setIsLoading]                   = React.useState(false)

  const getCompanies = (role) => {
    switch (role) {
      case userRole.installer:
        getInstallerCompanies()
        break
      case userRole.insurer:
        getInsurances()
        break
    }
  }

  /* Handle changes on the dropdown menu and update the state
  for Role
    Input: @param event => onChange event */
  const handleChangeRole = event => {
    getCompanies(event.target.value)
    setRole(event.target.value)
  }

  /* Handle changes on the dropdown menu and update the state
  for Insurance
    Input: @param event => onChange event */
  const handleChangeInsurance = (event, component) => {
    setInsuranceName(event.target.value)
    setInsuranceId(component.key)
  }

  /* Handle changes on the dropdown menu and update the state
   for installer
     Input: @param event => onChange event */
  const handleChangeInstaller = (event, component) => {
    setInstallerCompany(event.target.value)
    setInstallerCompanyId(component.key)
  }

  /* Construct body to send to add new user */
  const addUser = () => {
    let newUser = null
    switch (role) {
      case userRole.grohe_admin:
        newUser = {
          role    : role,
          user_id : props.userInfo.user_id
        }
        break
      case userRole.installer:
        newUser = {
          role         : role,
          user_id      : props.userInfo.user_id,
          installer_id : installerCompanyId
        }
        break
      case userRole.insurer:
        newUser = {
          role         : role,
          user_id      : props.userInfo.user_id,
          insurance_id : insuranceId
        }
        break
    }

    /* Add new user */
    return addNewUser(newUser)
      .then(res => {
        return props.refreshTable()
      })
      .catch(err => {
        return alert(err.message || err.response || err.status || err)
      })
  }

  /* Get all insurance companies */
  const getInsurances = () => {
    setIsLoading(true)
    return getPolicies()
      .then(res => {
        setInsurances(res)
        setInsuranceName(res[0].insurance_name)
        setInsuranceId(res[0].insurance_id)
        setIsLoading(false)
      })
      .catch(err => {
        return alert(err.message || err.response || err.status || err)
      })
  }

  /* Get all installers companies */
  const getInstallerCompanies = () => {
    setIsLoading(true)
    return getInstallers()
      .then(res => {
        setInstallers(res.installers)
        setInstallerCompany(res.installers[0].name)
        setInstallerCompanyId(res.installers[0].installer_id)
        setIsLoading(false)
      })
      .catch(err => {
        return alert(err.message || err.response || err.status || err)
      })
  }
  /* Render correct components for adding informations in function of the role
    Input: @param role => role of the new user */
  const renderRoleComponent = (role) => {
    switch (role) {
      case userRole.grohe_admin:
        return (<>
        </>)
      case userRole.installer:
        return (
          <>
            <InputLabel>{strings.adminView.InstallerCompanyNotOptional}</InputLabel>
            <Select
              name={'installerCompany'}
              value={installerCompany}
              onChange={handleChangeInstaller}
              startAdornment={
                <InputAdornment>
                  <BuildIcon style={{ margin: '0.5rem' }} />
                </InputAdornment>
              }
            >
              {installers
                .map((installer) => {
                  return (
                    <MenuItem key={installer.installer_id}
                      value={installer.name}>
                      {installer.name}
                    </MenuItem>)
                })}
            </Select>
          </>
        )
      case userRole.insurer:
        return (
          <>
            <InputLabel>{strings.adminView.insuranceIdNotOptional}</InputLabel>
            <Select
              name={'insuranceId'}
              value={insuranceName}
              onChange={handleChangeInsurance}
              startAdornment={
                <InputAdornment>
                  <BusinessIcon style={{ margin: '0.5rem' }} />
                </InputAdornment>
              }
            >
              {/* Make unique insurance_id in insurances array (Because whe recycle GetInstallationPackages call)  */}
              {insurances
                .filter((insurance, index, self) => {
                  return self
                    .findIndex(x => x.insurance_id === insurance.insurance_id) === index
                })
                .map((insurance) => {
                  return (<MenuItem
                    key={insurance.insurance_id}
                    value={insurance.insurance_name}>
                    {insurance.insurance_name}
                  </MenuItem>)
                })}
            </Select>
          </>
        )
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <Avatar alt="user" src={userImg} />
        </Grid>
        <Grid item xs={10}>
          <p><h3><b>{props.userInfo.firstname} {props.userInfo.lastname}</b></h3></p>
          <p>{strings.groheView.email}{props.userInfo.email}</p>
          <p>{strings.adminView.phone}: {props.userInfo.phone}</p>
          <p>{strings.adminView.language}: {props.userInfo.language}</p>
        </Grid>
        <Grid container
          style={{ width: '100%' }}
          alignItems="center"
          justify="center">
          <Grid item xs={4}>
            <InputLabel>{strings.adminView.role}</InputLabel>
            <Select
              name={'roleSelect'}
              value={role}
              onChange={handleChangeRole}
            >
              <MenuItem value={userRole.grohe_admin}>{strings.adminView.userRole.groheAdmin}</MenuItem>
              <MenuItem value={userRole.installer}>{strings.adminView.userRole.installer}</MenuItem>
              <MenuItem value={userRole.insurer}>{strings.adminView.userRole.insurer}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={8}
            id="roleComponent">
            {isLoading
              ? <CircularProgress size={24} style={{ position: 'relative', top: 4, float: 'left' }} />
              : renderRoleComponent(role)}
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <Button color="primary" style={{ float: 'right' }} onClick={() => { addUser() }}>
            {strings.adminView.addUser}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
