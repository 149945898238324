/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import strings from '../../../strings/Strings'
import Grid from '@material-ui/core/Grid'
import MUIDataTable from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import BlockIcon from '@material-ui/icons/Block'
import AddUserModalBox from '../../DataTableToolbarCustomComponents/GroheUser/AddUserModalBox'
import { CircularProgress } from '@material-ui/core'
import { getUserData } from '../../../../APICall/call'
import styles from '../../../Styles/responsiveRowOverride.css'
import { deleteUserRole } from '../../../../APICall/call'


const muiDatatableTheme = createMuiTheme({
  overrides: {
    styles
  }
})

const rowPageNumber = 15
/* Component that represents the permissions administration page */
class PermissionManagementSection extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      userData: [],
      ticket_roles: null,
    }
  }

  /* Get all users data */
  componentDidMount() {
    this.setState({ isLoading: true })
    return this.getUsersData()
  }

  /* Return the Modal Component to add new user */
  addUserModal = () => {
    return (<AddUserModalBox refreshTable={this.refreshTable} />)
  }

  /* Refresh the table after some update like revoke permissions */
  refreshTable = () => {
    return this.getUsersData()
  }

  /* Return User data to insert into the table
    Output: User Data */
  getUsersData = () => {
    return getUserData()
      .then(res => {
        let userData = res.ticket_roles.map((user) => {
          return [
            user.email,
            user.role,
            <DetailsAboutUser role={user.role} user={user} />,
            <ActionButtonGroup refreshTable={this.refreshTable} user={user}/>
          ]
        })

        this.setState({
          userData: userData,
          ticket_roles: res.ticket_roles,
          isLoading: false
        })
        return Promise.resolve()
      })
      .catch(err => {
        console.error(err);
        alert(strings.errors.getUsersError + ": " + (err.message || err.response || err.status || err))
        Promise.reject(err)
      })
  }


  render() {
    const columns = [{ name: 'User email', options: { sort: false } },
    { name: 'Role', options: { sort: false } },
    {
      name: 'Details',
      options: {
        sort: false,
        hint: strings.adminView.columnsHint.detailHint,
      }
    }
      ,
    {
      name: 'Permissions',
      options: {
        sort: false,
        hint: strings.adminView.columnsHint.permissionsHint,
      }
    }]

    const options = {
      selectableRows: false,
      download: false,
      print: false,
      search: true,
      filter: false,
      viewColumns: false,
      serverSide: false,
      rowsPerPage: rowPageNumber,
      rowsPerPageOptions: [rowPageNumber, 25, 50],

      customToolbar: () => {
        return (
          this.addUserModal()
        )
      }
    }

    return (
      <>
        <Grid item xs={12}>
          <MuiThemeProvider theme={muiDatatableTheme}>
            <MUIDataTable
              title={this.state.isLoading
                ? <CircularProgress size={24} style={{ position: 'relative', top: 4, float: "left" }} />
                : strings.adminView.tableTitle}
              data={this.state.userData}
              columns={columns}
              options={options}
              responsive={'scroll'}
            />
          </MuiThemeProvider>
        </Grid>
      </>
    )
  }
}

export default PermissionManagementSection

/* Button to revoke access rights to a specific user */
export function ActionButtonGroup(props) {

  const revokePermissions = () =>
  {
    console.debug("USER ID ", props.user)
    return deleteUserRole(props.user)
    .then(res =>{
      props.refreshTable()
      return Promise.resolve()
    })
    .catch(err =>{
      alert(err.message || err.response || err.status || err)
      return Promise.reject(err)
    })
  }

  return (
    <>
      <ButtonGroup style={{ display: 'flex' }} aria-label="outlined primary button group">
        <Button color="secondary"
          startIcon={<BlockIcon />}
          onClick={() => { revokePermissions()}}
        >{strings.adminView.buttonGroupStrings.revoke}
        </Button>
      </ButtonGroup>
    </>
  )
}

/* Function that return the component of details about user by role */
export function DetailsAboutUser(props) {
  const getDetailsByRole = () => {
    switch (props.role) {
      case 'grohe_admin':
        return (<>
          <div style={{ margin: 0, padding: 0 }}>
            <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.adminView.noDetails}</h5></p>
          </div>
        </>)
      case 'installer':
        return (
          <>
            <div style={{ margin: 0 }}>
              <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.dataTableParams.installerId}: <em>{props.user.installer_id}</em></h5></p>
              <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.dataTableParams.installerName}: <em>{props.user.installer_name}</em></h5></p>
              <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.dataTableParams.installerZipcode}: <em>{props.user.installer_zipcode}</em></h5></p>
              <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.dataTableParams.installerEmail}: <em>{props.user.installer_email}</em></h5></p>
            </div></>
        )
      case 'insurer':
        return (
          <>
            <div style={{ margin: 0, padding: 0 }}>
              <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.adminView.insuranceId}: <em>{props.user.insurance_id}</em></h5></p>
              <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.dataTableParams.insuranceName}: <em>{props.user.insurance_name}</em></h5></p>
              <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.adminView.insuranceCountry}: <em>{props.user.insurance_country}</em></h5></p>
              <p style={{ margin: 0, padding: 0 }}><h5 style={{ margin: 0, padding: 0 }}>{strings.adminView.insuranceEmail}: <em>{props.user.insurance_email}</em></h5></p>
            </div>
          </>
        )
    }
  }

  return (
    <>
      {getDetailsByRole()}
    </>
  )
}
