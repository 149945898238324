/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { Card, CardContent, Grid, IconButton } from '@material-ui/core'
import Image from 'react-bootstrap/Image'
import Typography from '@material-ui/core/Typography'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import strings from '../../strings/Strings'
import SenseImg from '../../../Assets/img/sense.png'
import GuardsImg from '../../../Assets/img/guard.png'

const deviceImages = {
    'Guard': GuardsImg,
    'Sense': SenseImg
}
export default class DevicesSubRow extends Component {

    getDeviceNameFromType = (type) => {
        return (type % 100) === 3
            ? strings.deviceSettingsView.deviceTypeGuard
            : (type % 100) === 1 || (type % 100) === 2
                ? strings.deviceSettingsView.deviceTypeSense
                : 'Unknown'
    }

    render() {
        return (
            !this.props.devices ||
                this.props.devices.length === 0
                ? <Typography>{strings.deviceSettingsView.noDevices}</Typography>
                : this.props.devices
                    .map((device, index) => {
                        return (
                            <Card key={index} style={{ marginLeft: '1rem', padding: 0, display: 'inline-block' }}>
                                <CardContent style={{ padding: 0 }}>
                                    <Grid container
                                        align="center"
                                        justify="center"
                                        direction="row"
                                        style={{ padding: '0.3rem', justifyContent: 'center' }}>
                                        <Grid item xs={2} align='center' style={{ alignSelf: 'center' }}>
                                            <Image src={deviceImages[this.getDeviceNameFromType(device.type)]}
                                                height='80'
                                                width='100'
                                                style={{ verticalAlign: 'center' }} />
                                        </Grid>
                                        <Grid item xs={6} align='center'  >
                                            <p style={{ color: '#112038', marginLeft: '2rem', fontSize: '1rem', textAlign: 'justify', marginTop: 0 }}>
                                                {this.getDeviceNameFromType(device.type)}
                                            </p>
                                            <p style={{
                                                color: '#424242',
                                                marginLeft: '2rem',
                                                fontSize: '0.7rem',
                                                textAlign: 'left',
                                                marginTop: 0
                                            }}>
                                                {strings.deviceSettingsView.applianceId}: {device.appliance_id}
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} align='center' style={{ alignSelf: 'center' }}>
                                            <IconButton
                                                style={{ marginLeft: '1rem' }}
                                                onClick={() => { this.props.deviceSettings(device) }}> {/* Callback of this device settings */}
                                                <ArrowDownwardIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )
                    })
        )
    }
}
