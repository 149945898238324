/* eslint-disable react/prop-types */
/* eslint-disable no-multi-spaces */
import React, { useReducer } from 'react'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import DashboardIcon from '@material-ui/icons/Dashboard'
import drawerStyles from '../../Styles/DrawerStyle.module.css'
import appBarStyle from '../../Styles/AppBarStyle.module.css'
import Image from 'react-bootstrap/Image'
import GroheLogo from '../../../Assets/img/grohe-logo.png'
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import strings from '../../strings/Strings'
import useStyles from '../../Styles/TopBarStyles'
import auth from '../../Home/GlobalValues/AuthenticationRole'
import DashboardSection from './Sections/DashboardSection'
import PermissionManagementSection from './Sections/PermissionManagementSection'
import permissions from '../GlobalValues/PermissionKeys'
import { logout } from '../../../APICall/call'
import ReactDOM from 'react-dom'
import Login from '../../Widget/html/React/Login'
import showError from '../../Errors/ErrorHandler'
import DevicesOtherIcon from '@material-ui/icons/DevicesOther'
import DeviceSettings from './Sections/DeviceSettings'
import LanguageSelect from './SubComponents/LanguageSelect'
import UserBadge from './SubComponents/UserBadge'

/* Component that contains the Dashboard (DataTable ...) */

export default function Dashboard (props) {
  /* Custom state management using Reducer */
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { open: false, drawerItemSelected: props.section, user: null })
  const classes           = useStyles.useStyles()
  const theme             = useTheme()

  const drawerItemsIcons = [
    <><DashboardIcon className={drawerStyles.iconStyles}/></>,
    <><SupervisorAccountIcon className={drawerStyles.iconStyles}/></>,
    <><DevicesOtherIcon className={drawerStyles.iconStyles}/></>
  ]

  const handleDrawerOpen = () => {
    setState({ open: true })
  }

  const handleDrawerClose = () => {
    setState({ open: false })
  }

  /* Show/Hide components in function of the user role */
  const getDrawerItemsForUser = () => {
    switch (localStorage.getItem(permissions.perms.role)) {
      case auth.auth.grohe:
        return [strings.groheView.dashboard, strings.groheView.permManagem, strings.groheView.deviceSettings]
      case auth.auth.insurance:
        return [strings.groheView.dashboard]
      case auth.auth.installer:
        return [strings.groheView.dashboard]
    }
    return [strings.groheView.dashboard]
  }

  const showGroheLoginPage = () => {
    ReactDOM.render(<Login />, document.getElementById('root'))
  }

  /* POST Call to logout from the IWD and render Login Page */
  const logoutFromIWD = () => {
    return logout()
      .then(res => {
        return showGroheLoginPage()
      })
      .catch(err => { return showError(err.response || err, showGroheLoginPage) })
  }

  const getDrawerItems = () => {
    return (
      <>
        <List>
          {getDrawerItemsForUser().map((text, index) => (
            <ListItem button key={text}
              onClick={() => { renderPage(text) }}
              style={{ color: 'white', fontFamily: 'LinotypeUnivers-430Regular' }}>
              <ListItemIcon>
                {drawerItemsIcons[index]}
              </ListItemIcon>
              <Typography >{text}</Typography>
            </ListItem>
          ))}
        </List>
      </>
    )
  }

  const forceDeviceSettingsView = (user) => {
    setState({
      drawerItemSelected : strings.groheView.deviceSettings,
      user               : user
    })
  }

  const returnPage = (page) => {
    switch (page) {
      case strings.groheView.permManagem:
        return (<PermissionManagementSection />)
      case strings.groheView.dashboard:
        return (<DashboardSection renderDeviceSettingsView={forceDeviceSettingsView}/>)
      case strings.groheView.deviceSettings:
        return (<DeviceSettings userCallback={!state.user ? null : state.user}/>)
    }
  }

  /* Render every section according to the drawer items
  Input: @param page => which page have to be showed */
  const renderPage = (page) => {
    switch (page) {
      case strings.groheView.permManagem:
        setState({ drawerItemSelected: strings.groheView.permManagem })
        break

      case strings.groheView.dashboard:
        setState({ drawerItemSelected: strings.groheView.dashboard })
        break

      case strings.groheView.deviceSettings:
        setState({ drawerItemSelected: strings.groheView.deviceSettings })
        break
    }
    handleDrawerClose()
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift] : state.open
        })}
      >
        <Toolbar className={appBarStyle.navbarStyle}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, state.open && classes.hide)}
          >

            <MenuIcon />
          </IconButton>
          <Image className={appBarStyle.groheLogo} src={GroheLogo} />
          <Typography variant="h6" className={appBarStyle.textStyle} noWrap>
            {strings.groheView.topbar}
          </Typography>

          <div style={{ display: 'flex' }}>
            <UserBadge />

            <LanguageSelect />
            <Button
              style={{
                fontFamily : 'url(../../Assets/fonts/LinotypeUnivers-630Bold.ttf)',
                marginLeft : '2rem',
                color      : 'black'
              }}
              onClick={() => { logoutFromIWD() }}>
              {strings.groheView.logout}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={state.open}
        classes={{
          paper : classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>

          <Grid container justify="center" alignItems="center">
            <Grid item xs={9}>

              <Typography variant="h6" style={{
                color      : 'white',
                float      : 'left',
                fontFamily : 'LinotypeUnivers-630Bold'
              }}>GROHE - IWD</Typography>

            </Grid>
            <Grid item xs={3}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr'
                  ? <ChevronLeftIcon className={drawerStyles.iconStyles} />
                  : <ChevronRightIcon className={drawerStyles.iconStyles} />}
              </IconButton>
            </Grid>
          </Grid>

        </div>

        {getDrawerItems()}

      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift] : state.open
        })}
      >
        <div className={classes.drawerHeader} />

        <Grid container id="mainContainer">
          {returnPage(state.drawerItemSelected)}
        </Grid>
      </main>
    </div>
  )
}
