/* eslint-disable no-multi-spaces */
/* eslint-disable react/prop-types */
import React from 'react'
import strings from '../../../strings/Strings'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { patchTicketBatch } from '../../../../APICall/call'
import Swal from 'sweetalert2'

/* Component representing the button
to edit the policy ID on tickets in "Open" status  */
export default function EditButton (props) {
  function editPolicyAlert (props) {
    Swal.fire({
      title            : strings.dataTableCustomComponentsStrings.changePolicy,
      input            : 'text',
      inputValue       : props.thisTicket[1],
      showCancelButton : true,
      inputValidator   : (value) => {
        if (!value) {
          return strings.errors.missingInsurancePolicyId
        }
      }
    })
      .then(result => {
        if (result.value) {
          return editPolicy(result.value, props)
        }
      })
      .catch(err => {
        return Swal.fire(strings.errors.somethinWentWrong, (err.message || err.response || err.status || err), 'error')
      })
  }

  function editPolicy (policy, props) {
    const ticketsToPatch = [{
      ticket_id           : props.thisTicket[0],
      insurance_policy_id : policy
    }]
    return patchTicketBatch(ticketsToPatch)
      .then(res => {
        if (res.data.failed &&
          res.data.failed.length > 0) {
          return Promise.reject(strings.errors.errorPatchTicket + res.data.failed)
        }
        props.canRefresh(true)
        return Promise.resolve()
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

  return (
    <Button variant="outlined"
      color="primary"
      style={{ margin: '0rem' }}
      onClick={() => { editPolicyAlert(props) }}
      startIcon={<EditIcon />}>
      {strings.tableButton.editButton}
    </Button>
  )
}
