/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from '@material-ui/core'
import strings from '../../../strings/Strings'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import { getTicketValidationStatus } from '../../../../APICall/call'
import Swal from 'sweetalert2'

/* Component responsible to validate status of an installed ticket  */

export default function CheckedTickets (props) {
  /* Method that validate an installed tickets to check
  if there was some problem during auto-validation [GET CALL]
    Callback: boolean: canRefresh (Refresh Table) */

  const validateTicket = () => {
    return getTicketValidationStatus(props.thisTicket[0])
      .then(res => {
        if (res.message) {
          Swal.fire(strings.validation.validateResponse, res.message, res.success ? 'success' : 'error')
          props.canRefresh(true)
          return
        }
        console.warn('missing res.message ', res)
        return Promise.reject(res)
      })
      .catch(err => {
        return Swal.fire('', err.message || err.response || err.status || err, 'error')
      })
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      style={{ margin: '0rem' }}
      onClick={() => { validateTicket() }}
      startIcon={<DoneAllIcon />}>
      {strings.dataTableToolbarCustomStrings.checked}
    </Button>
  )
}
