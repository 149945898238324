/* eslint-disable no-multi-spaces */
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import permissions from '../src/components/Home/GlobalValues/PermissionKeys'
import Login from '../src/components/Widget/html/React/Login'
import { getOAuth, getUserRole, refreshToken } from './APICall/call'
import showError from './components/Errors/ErrorHandler'
import PermissionManagementSection from './components/Home/MainComponents/Sections/PermissionManagementSection'
import DeviceSettings from './components/Home/MainComponents/Sections/DeviceSettings'

/* Function to fetch the state token if not present
if state_token is present => getOAuth */
function fetchStateToken () {
  if (localStorage.getItem(permissions.perms.state_token) &&
    localStorage.getItem(permissions.perms.access_token) &&
    localStorage.getItem(permissions.perms.refresh_token) &&
    localStorage.getItem(permissions.env.expires_in) &&
    localStorage.getItem(permissions.perms.role)) {
    return setTimer()
  }

  /* Here i get the state token searching for query parameter */

  const query = window.location.search.substring(1)
  const vars  = query.split('&')
  for (const queryparam of vars) {
    const param = queryparam.split('=')
    if (param[0] &&
      param[0].toLowerCase() === permissions.perms.state_token &&
      param[1]) {
      localStorage.setItem(permissions.perms.state_token, param[1])
      return getOAuth()
        .then(response => {
          for (const param of Object.keys(response.data)) {
            localStorage.setItem(param, response.data[param])
          }

          return Promise.resolve(response)
        })
        .then(response => { return getUserRole(response) })
        .then(response => { return setTimer() })
        .catch(err => { console.error(err); return Promise.reject(showError(err, showGroheLoginWidget)) })
    }
    return showGroheLoginWidget()
  }
}

/* Function that calculates milliseconds before the Refresh Token
expire.  */
function getDiffTime () {
  const epoch = new Date(0) // Epoch timestamp
  epoch.setUTCSeconds(localStorage.getItem(permissions.env.expires_in)) // Set Epoch + expire time
  const now  = new Date() // Current date
  const diff = ((epoch - now) - 10000) // Refresh token 10 seconds before (Expire_in - 10000) = diff (milliseconds)
  return diff
}

/* Set timer to refresh token after it expire */
function setTimer () {
  let timeoutId  = null
  const diffTime = getDiffTime()
  /* I have all permissions to set a timer to refresh token */
  if (localStorage.getItem(permissions.perms.state_token) &&
    localStorage.getItem(permissions.perms.access_token) &&
    localStorage.getItem(permissions.perms.refresh_token) &&
    localStorage.getItem(permissions.env.expires_in)) {
    /* No timeout ID, so this is the first access to the dashboard
        then settings the timeout */
    if (!localStorage.getItem(permissions.env.timeout_id)) {
      timeoutId = setTimeout(startRefreshToken, diffTime)
      localStorage.setItem(permissions.env.timeout_id, timeoutId)
      return startDashboard()
    } else {
      /* I have the timeout ID stored and getDiffTime return a positive value,
            so i have refreshed the page because index.js is executed and the timeout ID is stored
            But after a refresh all timers, timouts and interval are destroyed so i have to recreate the timer with the current
            diff time and overwrite the new ID to the local storage  */

      clearTimeout(localStorage.getItem(permissions.env.timeout_id))

      if (diffTime > 0) {
        timeoutId = setTimeout(startRefreshToken, diffTime)
        localStorage.setItem(permissions.env.timeout_id, timeoutId)
        return startDashboard()
      }
      /* My timeout ID is stored but the diff time is negative,
            this means that the browser window was close with all local storage value set but
            there wasn't any refresh of token, then need a new refresh of the token to gain
            new expires_in timestamp and recreate/restore new timeout */

      return startRefreshToken()
        .then(res => {
          return startDashboard()
        })
        .catch(err => { return Promise.reject(err) })
    }
  }
}

/* Function that initialize refreshToken call
and set new Timeout after refresh is done */
function startRefreshToken () {
  let timeoutId = null
  return refreshToken()
    .then(res => { /* create new timeout */
      timeoutId = setTimeout(startRefreshToken, getDiffTime()) // getDiffTime() is needed because expires_in is changed
      localStorage.setItem(permissions.env.timeout_id, timeoutId)
      return Promise.resolve()
    })
    .catch(err => { return Promise.reject(showError(err, showGroheLoginWidget)) })
}

/* Main GROHE login widget */

function showGroheLoginWidget () {
  /* Clear local storage because I am in the main login widget
  then I have to acquire credentials again */
  localStorage.clear()
  ReactDOM.render((
    <Login />
  ), document.getElementById('root'))
}

/* Function that start Dashboard
after acquire correct permissions, role and set timeout fo the refresh token */

function startDashboard () {
  ReactDOM.render(
    <App />,
    document.getElementById('root'))
}

fetchStateToken()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
