import React from 'react'
import ReactDOM from 'react-dom'
import Error from './Error'

/* Function that render the Error page
Input: @param response => Message/Response
    @param (function) login => callback to the login page if needed */
export default function showError (response, login) {
  ReactDOM.render(<Error
    response={response}
    login={login || ''} />,
  document.getElementById('root'))
}
