/* eslint-disable no-multi-spaces */
import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import rStyles from '../../../Styles/responsiveRowOverride.css'
import { CircularProgress, Card, Divider, Box, CardHeader, Icon, CardActions } from '@material-ui/core'
import strings from '../../../strings/Strings'
import TextField from '@material-ui/core/TextField'
import validator from 'validator'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import ExpandedRowDeviceView from '../SubComponents/ExpandedRowDeviceView'
import DevicesSubRow from '../../DataTableBodyComponents/DevicesSubRow'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close';
import { getDeviceSettingsByUser, getUserFromEmail } from '../../../../APICall/call'
import Paper from '@material-ui/core/Paper'

const styles = {
  h3: {
    marginBottom: "0.5rem",
    backgroundColor: "#21D4FD",
    backgroundImage: "linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
    color: "#EEEEEE",
    padding: "0.2rem",
    paddingLeft: "0.4rem",
    borderRadius: "4px",
  },
  subBackground:
  {
    background: "#bdc3c7",
    backgroundImage: "linear-gradient(to left, #bdc3c7, #2c3e50)",
    marginTop: "1rem",
    marginLeft: '1rem',
    display: 'flex',
    color: '#EEEEEE',
    padding: "0.2rem",
    paddingLeft: "0.4rem",
    borderRadius: "4px"
  },
  subBackground2:
  {
    background: "#bdc3c7",
    backgroundImage: "linear-gradient(to left, #bdc3c7, #2c3e50)",
    marginLeft: '2rem',
    display: 'flex',
    color: '#EEEEEE',
    padding: "0.2rem",
    paddingLeft: "0.4rem",
    borderRadius: "4px",
    marginTop: '1rem'
  }
}

const columns = [
  { name: strings.deviceSettingsView.locationId, options: { filterType: 'textField' } },
  { name: strings.deviceSettingsView.roomId, options: { filterType: 'textField' } },
  strings.deviceSettingsView.locationName,
  strings.deviceSettingsView.room,
]

const rowPageNumber = 100 // Numbers of rows per page
const rowsPerPageNumber = [100] // Array of numbers of rows per page allowed

const muiDatatableTheme = createMuiTheme({
  overrides: {
    rStyles
  }
})

export default class DeviceSettings extends Component {

  constructor(props) {
    super(props)
    let user = props.userCallback

    let email = ''
    let isEmail = false
    let userId = null
    if (user) {
      email = user.email
      isEmail = validator.isEmail(email)
      userId = user.user_id
    }

    this.state = {
      rowPageNumber: rowPageNumber,
      rowPerPage: rowsPerPageNumber,
      isLoading: false,
      email: email,
      isEmail: isEmail,
      locationData: [],
      devices: null,
      deviceSettings: null,
      tableData: [],
      isDeviceButton: false,
      userId: userId
    }
  }

  /* It can be useful during implementation of the feature to redirect from checked ticket buttons */
  componentDidMount() {
    if (this.state.email !== ''
      && this.state.isEmail
      && this.state.userId) {
      this.getInsuredLocationsFromEmail()
    }
  }

  /* Scroll into the correct referenced <div> after deviceSettings changes */
  componentDidUpdate() {
    if (this.refs.configView && this.state.isDeviceButton) {
      this.refs.configView.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  /* OnChange email event */
  onChangeText = (event) => {
    let email = event.target.value
    this.setState({ email: email, isEmail: validator.isEmail(email), isDeviceButton: false })
  }

  /* Set device settings (returned from callback of DevicesSubRow) */
  setDeviceSettings = (settings) => {
    this.setState({ deviceSettings: settings, isDeviceButton: true })
  }


  /* Parse data of insured_locations*/
  getLocationData = (insuredLocations) => {
    let tableData = []
    let roomAppliances = {}
    for (let insured_location of insuredLocations) {
      for (let room of insured_location.rooms) {
        roomAppliances[room.id] = room.appliances.map((appliance) => { return appliance })
        tableData.push([insured_location.id, room.id, insured_location.name, room.name])
      }
    }
    this.setState({
      tableData: tableData,
      devices: roomAppliances,
      isDeviceButton: false
    })
  }

  /* Get devices of a room */
  getDevices = (selectedRoom) => {
    return <DevicesSubRow
      devices={this.state.devices[selectedRoom]}
      deviceSettings={this.setDeviceSettings}
    />
  }

  /* Get insured locations from user email */
  getInsuredLocationsFromEmail = () => {
    this.setState({ isLoading: true, isDeviceButton: false, deviceSettings: null })
    /* Check if i have userId already otherwise i have to retrieve that from user email */
    let firstCall = this.state.userId
      ? getDeviceSettingsByUser(this.state.userId)
      : getUserFromEmail(this.state.email)
      .then(res => {
        return getDeviceSettingsByUser(res.user_id)
      })


    firstCall
      .then(res => {
        this.getLocationData(res.insured_locations)
        this.setState({ locationData: res.insured_locations, isLoading: false, isDeviceButton: false })
        return
      })
      .catch(err => {
        this.setState({ isLoading: false, isDeviceButton: false })
        if ((err.status || err.response.status) === 404) {
          return alert(strings.errors.noUserFound)
        }
        return alert(err.message || err.response || err.status || err)
      })
  }

  /* Get the device name from the type:
  result == 1 || 2 then Sense 
  else if result === 3 then Guard
   else Unknown
   The check for both is implemented in case of future changes (i.e. other devices*/
  getDeviceNameFromType = (type) => {
    return (type % 100) === 3
      ? strings.deviceSettingsView.deviceTypeGuard
      : (type % 100) === 1 || (type % 100) === 2
        ? strings.deviceSettingsView.deviceTypeSense
        : 'Unknown'
  }

  render() {
    /* Data Table Options */
    const textLabels = {
      body: {
        noMatch: strings.muiDataTableStrings.body.noMatch,
        toolTip: strings.muiDataTableStrings.body.toolTip
      },
      selectedRows: {
        text: strings.muiDataTableStrings.selectedRows.rowsSelected
      },
      viewColumns: {
        title: strings.muiDataTableStrings.viewColumns.showColumns,
        titleAria: strings.muiDataTableStrings.viewColumns.titleAria
      },
      filter: {
        all: strings.muiDataTableStrings.filter.all,
        title: strings.muiDataTableStrings.filter.title,
        reset: strings.muiDataTableStrings.filter.reset
      }
    }

    const options = {
      download: false,
      print: false,
      filter: true,
      viewColumns: false,
      search: true,
      selectableRows: false,
      serverSide: false,
      rowsPerPage: this.state.rowPageNumber,
      rowsPerPageOptions: this.state.rowPerPage,
      expandableRows: true,
      textLabels: textLabels,
      renderExpandableRow: (rowData, rowMeta) => {
        return (
          <ExpandedRowDeviceView
            devices={this.getDevices(rowData[1])}
            rowDataLength={rowData.length} />)
      },
      customFooter: () => {
        return (<></>)
      }
    }
    return (

      <Grid container
        style={{ padding: '1rem' }}>
        <Grid item xs={12}>
          <TextField
            id="email"
            label={this.state.isEmail || this.state.email === ''
              ? strings.deviceSettingsView.userEmail
              : <h4 style={{ color: 'red', margin: 0 }}>{strings.errors.emailExactMatch}</h4>}
            type="search"
            color={this.state.isEmail || this.state.email === '' ? 'primary' : 'secondary'}
            style={{ marginBottom: '1rem', width: '50%' }}
            variant="outlined"
            value={this.state.email !== '' ? this.state.email : ''}
            onChange={(e) => { this.onChangeText(e) }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    disabled={!this.state.isEmail}
                    onClick={() => { this.getInsuredLocationsFromEmail() }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiThemeProvider theme={muiDatatableTheme}>
            <MUIDataTable
              title={this.state.isLoading &&
                <CircularProgress size={24} style={{ position: 'relative', top: 4, float: 'left' }} />}
              data={this.state.tableData}
              columns={columns}
              options={options}
              responsive={'scroll'}
            />
          </MuiThemeProvider>
        </Grid>

        {!this.state.deviceSettings
          ? <></>
          : <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Card>
              <CardHeader
                action={
                  <div ref="configView">
                    <IconButton
                      onClick={() => { this.setState({ deviceSettings: null, isDeviceButton: false }) }}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                }>
              </CardHeader>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {this.getDeviceNameFromType(this.state.deviceSettings.type)}
                    </Typography>
                  </Grid>

                  {getDeviceSettingsContent(this.state.deviceSettings,
                    this.getDeviceNameFromType(this.state.deviceSettings.type))}

                </Grid>
              </CardContent>
            </Card>
          </Grid>
        }
      </Grid>
    )
  }
}



/* Get device settings in function of the type (guard, sense) */
export function getDeviceSettingsContent(deviceSettings, type) {
  return (
    <Grid container>
      {
        type === strings.deviceSettingsView.deviceTypeGuard
          ? getDeviceGuardConfigs(deviceSettings)
          : type === strings.deviceSettingsView.deviceTypeSense
            ? getDeviceSenseConfigs(deviceSettings)
            : <p>Uknown</p>
      }
    </Grid>
  )
}

/* Build device settings view */

export function getDeviceGuardConfigs(deviceSettings) {

  return (
    <>
      <Grid item xs={12}>
        <h3 style={styles.h3}>{strings.deviceSettingsView.generalInformations}</h3>
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.applianceId}: </h4>{deviceSettings.appliance_id}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.installation_date}: </h4>{deviceSettings.installation_date}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.serial_number}: </h4>{deviceSettings.serial_number}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.type}: </h4>{deviceSettings.type}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.version}: </h4>{deviceSettings.version}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.tdt}: </h4>{deviceSettings.tdt}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.timezone}: </h4>{deviceSettings.timezone}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.role}: </h4>{deviceSettings.role}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.registration_complete}: </h4>{deviceSettings.registration_complete.toString()}
      </Grid>
      <Grid item xs={12}>
        <h3 style={styles.h3}>{strings.deviceSettingsView.configInformations}</h3>
      </Grid>
      <Grid item xs={12}>
        <Paper
          style={{ padding: "1rem", margin: '0rem', float: "right" }}>
          {Object.keys(deviceSettings.config)
            .slice(0, (Object.keys(deviceSettings.config).length / 2))
            .map((configKey, index) => {
              if (configKey !== 'thresholds') {
                return (
                  <>
                    <Grid item xl={12}
                      key={index}>
                      <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{configKey}: </h4>{deviceSettings.config[configKey].toString()}
                    </Grid>
                  </>
                )
              }
            })}
        </Paper>

        <Paper
          style={{ padding: "1rem", marginLeft: "1rem", marginTop: 0, marginBottom: '1rem', marginRight: 0, float: "left" }}>
          {Object.keys(deviceSettings.config)
            .slice((Object.keys(deviceSettings.config).length / 2), Object.keys(deviceSettings.config).length)
            .map((configKey, index) => {
              if (configKey !== 'thresholds') {
                return (
                  <>
                    <Grid item xl={12}
                      key={index}>
                      <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{configKey}: </h4>{deviceSettings.config[configKey].toString()}
                    </Grid>
                  </>
                )
              }
            })}
        </Paper>
      </Grid>

      <Grid item xs={12} style={styles.subBackground}>
        <h3 style={{ margin: 0 }}>{strings.deviceSettingsView.thresholds}</h3>
      </Grid>
      {deviceSettings.config.thresholds
        .map((threshold, index) => {
          return (
            <>
              <Paper
                key={index}
                style={{ padding: "1rem", margin: '1rem' }}>
                <Grid item xl={12} >
                  <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{strings.deviceSettingsView.quantity}: </h4>{threshold.quantity}
                </Grid>
                <Grid item xl={12} >
                  <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{strings.deviceSettingsView.type}: </h4>{threshold.type}
                </Grid>
                <Grid item xl={12} >
                  <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{strings.deviceSettingsView.value}: </h4>{threshold.value}
                </Grid>
                <Grid item xl={12} >
                  <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{strings.deviceSettingsView.enabled}: </h4>{threshold.enabled.toString()}
                </Grid>
              </Paper>
            </>
          )
        })}
    </>
  )
}

export function getDeviceSenseConfigs(deviceSettings) {
  return (
    <>
      <Grid item xs={12}>
        <h3 style={styles.h3}>{strings.deviceSettingsView.generalInformations}</h3>
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.applianceId}: </h4>{deviceSettings.appliance_id}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.installation_date}: </h4>{deviceSettings.installation_date}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.serial_number}: </h4>{deviceSettings.serial_number}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.type}: </h4>{deviceSettings.type}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.version}: </h4>{deviceSettings.version}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.tdt}: </h4>{deviceSettings.tdt}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.timezone}: </h4>{deviceSettings.timezone}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.role}: </h4>{deviceSettings.role}
      </Grid>
      <Grid item xs={12} style={{ alignSelf: 'left', display: 'inline-flex' }}>
        <h4 style={{ margin: "0.1rem" }}>{strings.deviceSettingsView.registration_complete}: </h4>{deviceSettings.registration_complete.toString()}
      </Grid>
      <Grid item xs={12}>
        <h3 style={styles.h3}>{strings.deviceSettingsView.configInformations}</h3>
      </Grid>

      <Grid item xs={12} style={styles.subBackground}>
        <h3 style={{ margin: 0 }}>{strings.deviceSettingsView.thresholds}</h3>
      </Grid>
      {deviceSettings.config.thresholds
        .map((threshold, index) => {
          return (
            <>
              <Paper
                key={index}
                style={{ padding: "1rem", margin: '1rem' }}>
                <Grid item xl={12} >
                  <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{strings.deviceSettingsView.quantity}: </h4>{threshold.quantity}
                </Grid>
                <Grid item xl={12} >
                  <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{strings.deviceSettingsView.type}: </h4>{threshold.type}
                </Grid>
                <Grid item xl={12} >
                  <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{strings.deviceSettingsView.value}: </h4>{threshold.value}
                </Grid>
                <Grid item xl={12} >
                  <h4 style={{ margin: "0.1rem", display: 'inline-flex' }}>{strings.deviceSettingsView.enabled}: </h4>{threshold.enabled.toString()}
                </Grid>
              </Paper>
            </>
          )
        })}
    </>
  )
}