/* eslint-disable no-multi-spaces */
/* eslint-disable react/prop-types */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import BuildIcon from '@material-ui/icons/Build'
import AssignmentIndIcon from 'material-react-icons/AssignmentInd'
import strings from '../../strings/Strings'
import { Divider, Tooltip } from '@material-ui/core'

/* Component that shows installers and provide to assign
Input: Props,
Output: Callback of the installer assigned */

export default function SearchResultComponent (props) {
  /* Assign tickets to installer
  (Callback to the InstallerModalBox => method installerAssigned) */
  const assignTicketsToInstaller = (installer, index) => {
    props.installerAssigned(installer) // return back the installer assigned
  }

  /* Populate the Sub List Item text with more informations about this installer */
  const getSecondaryInformations = (installer) => {
    return (
      <>
        <h5 style={{ margin: '0' }}>
          {strings.installerDetailsFilterView.id}<em>{installer.installer_id}</em>
        </h5>
        <h5 style={{ margin: '0' }}>
          {strings.installerDetailsFilterView.email}<em>{installer.email}</em>
        </h5>
        <h5 style={{ margin: '0' }}>
          {strings.installerDetailsFilterView.zipcode}<em>{installer.zipcode}</em>
        </h5>

        <h5 style={{ margin: '0' }}>
          {strings.installerDetailsFilterView.sap_id}<em>{installer.sap_id}</em>
        </h5>
        <h5 style={{ margin: '0' }}>
          {strings.installerDetailsFilterView.pending_installations}<em>{installer.pending_installations}</em>
        </h5>
        <h5 style={{ margin: '0' }}>
          {strings.installerDetailsFilterView.completed_installations}<em>{installer.complete_installations}</em>
        </h5>
        <Divider></Divider>
      </>
    )
  }

  /* Constructor of list items of installers */
  const getArrayOfAllAvailableInstallers = () => {
    return (<>
      <List style={{ maxHeight: '20rem', overflow: 'auto' }}>
        {props.certifiedInstallers.installers.map((installer, index) => {
          return (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <BuildIcon style={{ color: '#112038' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<div style={{ marginBottom: '0.2rem' }}>{installer.name}</div>}
                secondary={getSecondaryInformations(installer)}
              />
              <ListItemSecondaryAction>
                <Tooltip title={strings.installerModalBox.assignTooltip}>
                  <IconButton edge="end" aria-label="assign" onClick={() => { assignTicketsToInstaller(installer, index) }}>
                    <AssignmentIndIcon color="#4CAF50" />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>)
        })}
      </List>
    </>)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        { getArrayOfAllAvailableInstallers()}
      </Grid>
    </Grid>
  )
}
