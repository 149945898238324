/* eslint-disable react/prop-types */
import React from 'react'
import Button from '@material-ui/core/Button'
import strings from '../../../strings/Strings'
import FailedAlert from '../../Dialogs/FailedAlert'
import AppointmentMadeAlert from '../../Dialogs/AppointmentMadeAlert'
import states from '../../GlobalValues/PreviousStatusCheck'
import { patchTicketBatch } from '../../../../APICall/call'

const statusTypes = {
  failed    : 'Failed',
  am        : 'appointment_made',
  installed : 'Installed',
  ui        : 'under_investigation'
}

/* Class that takes care of allow installer to change
status of selected tickets */

export default function InstallerViewStatusUpdate (props) {
  /* Set correct status button enabled in function of the status of
  selected tickets
  Logic: There is some ticket status that isn't included on the related previous states?

 => TRUE: Button disabled
 => FALSE: Button enabled
  */
  const setRightButtonsEnabled = (status) => {
    const isDisabled = props.ticketSelected
      .some((ticket) => {
        return status.previous_states
          .includes(ticket[ticket.length - 1]) === false
      })
    return isDisabled
  }

  /* Handle the reason of Failed Status and PATCH the ticket */
  const handleReasonCallback = (reason) => {
    updateStatus(statusTypes.failed, reason, null)
  }

  /* Handle the Date of Appointment and PATCH the ticket */
  const handleDateCallback = (date) => {
    updateStatus(statusTypes.am, null, date)
  }

  /* Build the correct array of tickets to PATCH
  with correct body */

  const constructPatchBody = (type, reason, date) => {
    const ticketToPatch = props.ticketSelected
      .map((ticket) => {
        return {
          ticket_id   : ticket[0],
          status      : type.toLowerCase(),
          reason      : reason,
          target_date : date
        }
      })
    console.log('TICKET TO PATCH', ticketToPatch)
    return ticketToPatch
  }

  /* Capture the type of update and the new status
    by performing the PATCH with the aforementioned new parameters
    Input: @param (type, status) => (Type of status update, new status (or other info such as: reason of failed...)) */

  const updateStatus = (type, reason, date) => {
    const ticketToPatch = constructPatchBody(type, reason, date)
    return patchTicketBatch(ticketToPatch)
      .then(res => {
        if (res.data.failed &&
            res.data.failed.length > 0) {
          return Promise.reject(strings.errors.errorPatchTicket + res.data.failed)
        }
        console.debug('Ticket patched', res)
        return props.canRefresh(true)
      })
      .catch(err => {
        return alert(strings.errors.somethinWentWrong + (err.message || err.response || err.status || err))
      })
  }

  return (
    <>
      <div>
        <Button
          disabled={setRightButtonsEnabled(states.allowed_status_update.role.installer.status.installed)}
          variant="outlined"
          color="primary"
          onClick={() => updateStatus(statusTypes.installed, null, null)}
          style={{ margin: '0.5rem' }}>
          {strings.dataTableToolbarCustomStrings.installed}
        </Button>

        <FailedAlert
          isDisabled={setRightButtonsEnabled(states.allowed_status_update.role.installer.status.failed)}
          reasonCallback={handleReasonCallback} />

        <AppointmentMadeAlert
          isDisabled={setRightButtonsEnabled(states.allowed_status_update.role.installer.status.appointment_made)}
          dateCallback={handleDateCallback} />

        <Button
          disabled={setRightButtonsEnabled(states.allowed_status_update.role.installer.status.under_investigation)}
          variant="outlined" color="primary"
          onClick={() => updateStatus(statusTypes.ui, null, null)}
          style={{ margin: '0.5rem' }}>
          {strings.dataTableToolbarCustomStrings.underInvestigation}
        </Button>
      </div>
    </>
  )
}
