/* eslint-disable react/prop-types */
/* eslint-disable no-multi-spaces */
import React from 'react'
import { IconButton } from '@material-ui/core'
import CloudDownloadIcon from 'material-react-icons/CloudDownload'
import Tooltip from '@material-ui/core/Tooltip'
import strings from '../../../strings/Strings'
import { ExportToCsv } from 'export-to-csv'
import { getCsvData } from '../../../../APICall/call'
import ReactDOM from 'react-dom'

import ReactExport from 'react-export-excel'
import { getQueryParamsByColumns } from '../../DataTableBodyComponents/Columns'

const ExcelFile   = ReactExport.ExcelFile
const ExcelSheet  = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

/* JSX Component responsible for exporting all tickets and
related information in csv / excel format (to be implemented soon) */

export default function CsvExport (props) {
  const [excelData, setExcelData] = React.useState(null)
  const types                     = {
    csv : 'csv',
    xls : 'xls'
  }

  const getData = (type) => {
    let csvOptions    = null
    const queryParams = getQueryParamsByColumns(props.filters)

    return getCsvData(queryParams)
      .then(tickets => {
        switch (type) {
          case types.csv:
            csvOptions = {
              fieldSeparator   : ',',
              quoteStrings     : '"',
              decimalSeparator : '.',
              showLabels       : true,
              showTitle        : true,
              title            : 'GROHE Tickets',
              useTextFile      : false,
              useBom           : true,
              useKeysAsHeaders : true,
              filename         : 'Tickets'
            }
            new ExportToCsv(csvOptions).generateCsv(tickets)
            break
          case types.xls:
            ReactDOM.render(<ExcelExport data={tickets}/>, document.getElementById('xlsDiv')) // Render component (Render == direct download)
            ReactDOM.unmountComponentAtNode(document.getElementById('xlsDiv')) // Unmount all childs (unmount allow to download again, otherwise ReactDOM.render make nothing)
            break
        }
        return Promise.resolve()
      })
      .catch(err => {
        alert(err)
        return Promise.reject(err)
      })
  }

  return (
    <>
      <span id="xlsDiv"></span>
      <Tooltip title={strings.tooltips.exportExcel}>
        <IconButton onClick={() => { getData(types.xls) }}>
          <CloudDownloadIcon color="#209f62" />
        </IconButton>
      </Tooltip>

      <Tooltip title={strings.tooltips.exportCsv}>
        <IconButton onClick={() => {
          getData(types.csv)
        }}>
          <CloudDownloadIcon color="#3F51B5" />
        </IconButton>
      </Tooltip>
    </>
  )
}

/* Function to export tickets dataset in XLS format */
function ExcelExport (props) {
  return (
    <>
      <div id="excelComponent">
        <ExcelFile
          hideElement={true}
          filename={strings.XLS_general.xls_filename}
        >
          <ExcelSheet data={props.data} name="Tickets" >
            {[Object.values(strings.csv_export_columns).map((value) => {
              return <ExcelColumn key={value} label={value} value={value}/>
            })]}
          </ExcelSheet>

        </ExcelFile>
      </div>
    </>)
}
