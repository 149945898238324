/* eslint-disable camelcase */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-multi-spaces */
import permissions from '../components/Home/GlobalValues/PermissionKeys'
import axios from 'axios'
import apiPaths from './config/apiPaths'
const baseUrl = require('./config/config-' + process.env.REACT_APP_ENV + '.json')

/* It builds the back end call according to the page,filters
or ticketid for more data about this
Input: @param Default Filters, Filter list, current page of the table, Page Sizes */
export function getTickets (queryParams) {
  queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)
  // console.debug('QUERY PARAMS GET TICKETS', queryParams)
  const queryOptions = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.getTickets)
  }

  return axios(queryOptions)
    .then(res => {
      // console.debug('RESULT GET TICKETS', res)
      if (res && res.status !== 200) {
        console.debug('Something went wrong when get tickets')
        return Promise.reject(res)
      }
      if (res.data && res.data.tickets) {
        const tickets = res.data.tickets.map((ticket) => {
          const newTicket = [ticket.id,
            ticket.insurance.insurance_policy_id,
            ticket.insurance.name,
            ticket.insurance.id,
            ticket.location.zipcode,
            ticket.location.city,
            ticket.status]
          return newTicket
        })

        return Promise.resolve({
          tickets              : tickets,
          totalNumberOfRecords : res.data.totalNumberOfRecords,
          totalNumberOfPages   : res.data.totalNumberOfPages,
          currentPage          : res.data.currentPage,
          jsonTickets          : res.data.tickets // Could be useful for next changes (Table data from JSON)
        })
      }
      return Promise.reject('Something went wrong, missings res.data ' + res)
    })
    .catch(err => { return Promise.reject(err.response) })
}

export function getSingleTicket (ticketId) {
  const queryParams = {
    state_token : localStorage.getItem(permissions.perms.state_token)
  }

  const queryOptions = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.getSingleTicket).replace('{ticketId}', ticketId)
  }

  return axios(queryOptions)
    .then(res => {
      // console.debug('RESULT GET TICKET', res)
      if (res && res.status !== 200) {
        console.debug('Something went wrong when get ticket ' + ticketId)
        return Promise.reject('Something went wrong when get ticket ' + ticketId + res)
      }
      if (res.data) {
        return Promise.resolve(res.data)
      }
      return Promise.reject('Something wen wrong, missings res.data ' + res)
    })
    .catch(err => { return Promise.reject(err.response) })
}

/* Get OAuth after POST request with state token */
export function getOAuth () {
  const options = {
    method  : 'POST',
    headers : {
      'content-type' : 'application/json',
      Accept         : 'application/json'
    },
    data : {
      state_token : localStorage.getItem(permissions.perms.state_token)
    },
    url : (baseUrl.idp.endpoint + apiPaths.webauthAuth)
  }

  return axios(options)
    .then(response => {
      if (response &&
        response.status &&
        response.status !== 200) {
        return Promise.reject(response)
      }
      if (response.data &&
        response.data.access_token &&
        response.data.refresh_token &&
        response.data.expires_in &&
        response.data.state_token) {
        return Promise.resolve(response)
      }
      return Promise.reject('Error GET OAuth ' + response)
    })
    .catch(err => { return Promise.reject(err.response) })
}

/* Get user role  */
export function getUserRole (response) {
  const options = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + response.data.access_token
    },
    params :
    {
      state_token : response.data.state_token
    },
    url : (baseUrl.idp.endpoint + apiPaths.role)
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        Promise.reject(res)
      } else if (res.data && res.data.role) {
        localStorage.setItem(permissions.perms.role, res.data.role)
        return Promise.resolve()
      } else {
        return Promise.reject(res)
      }
    })
    .catch(err => { return Promise.reject(err.response) })
}

/* Function that Refresh the token */
export function refreshToken () {
  const options = {
    method  : 'POST',
    headers : {
      'content-type' : 'application/json'
    },
    data : {
      state_token   : localStorage.getItem(permissions.perms.state_token),
      refresh_token : localStorage.getItem(permissions.perms.refresh_token)
    },
    url : (baseUrl.idp.endpoint + apiPaths.webauthRefresh)
  }

  return axios(options)
    .then(response => {
      if (response &&
        response.status &&
        response.status !== 200) {
        return Promise.reject(response)
      }
      if (response.data &&
        response.data.access_token &&
        response.data.refresh_token &&
        response.data.expires_in &&
        response.data.state_token) {
        console.debug('Storing new permissions from refresh token')
        /* Store new permissions */
        for (const param of Object.keys(response.data)) {
          localStorage.setItem(param, response.data[param])
        }
        return Promise.resolve()
      }
      console.warn('Something went wrong when refresh token, missing some/all parameters of response.data')
      localStorage.clear()
      return Promise.reject(response)
    }).catch(err => {
      console.error('Refresh token catch error', err.response)
      return Promise.reject(err.response)
    })
}
/* Function that get date for CSV/XLS */
export function getCsvData (queryParams) {
  // console.debug('GET CSV QUERY PARAMS', queryParams)
  const queryOptions = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.getTickets)
  }

  return axios(queryOptions)
    .then(res => {
      if (res && res.status !== 200) {
        console.warn('Something went wrong when get tickets for csv data')
        return Promise.reject(res)
      }
      if (res.data && res.data.tickets) {
        const tickets = res.data.tickets.map((ticket) => {
          const newTicket = {
            'Ticket.ID'                  : ticket.id,
            'Ticket.Status'              : ticket.status,
            'Ticket.Insurance.Policy_ID' : ticket.insurance.insurance_policy_id,
            'Ticket.Insurance.Id'        : ticket.insurance.id,
            'Ticket.Insurance.Name'      : ticket.insurance.name,
            'Ticket.Location.ID'         : ticket.location.id,
            'Ticket.Location.Name'       : ticket.location.name,
            'Ticket.Location.ZipCode'    : ticket.location.zipcode,
            'Ticket.Location.City'       : ticket.location.city,
            'Ticket.Location.Street'     : ticket.location.street,
            'Ticket.Installer.ID'        : ticket.installer ? ticket.installer.id : 'None',
            'Ticket.Installer.Email'     : ticket.installer ? ticket.installer.email : 'None',
            'Ticket.Installer.Name'      : ticket.installer ? ticket.installer.name : 'None',
            'Ticket.Installer.ZipCode'   : ticket.installer ? ticket.installer.zipcode : 'None'
          }
          return newTicket
        })
        return Promise.resolve(tickets)
      }
      return Promise.reject('Something wen wrong, missings res.data ' + res)
    })
    .catch(err => { return Promise.reject(err.response) })
}

/* Function that get Certified Installers from the backend */
export function getInstallers () {
  const queryParams = {}

  queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)

  const options = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.certifiedInstallers)
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        console.error('Something went wrong when Get Certified Installers ', res)
        return Promise.reject(res)
      }
      if (res.data &&
        res.data.installers) {
        console.debug('Get Certified Installers results: ', res)
        return Promise.resolve(res.data)
      }
      console.error('Something went wrong', res)
      return Promise.reject(res)
    })
    .catch(err => { return Promise.reject(err) })
}

/* GET Insurance Policies */
export function getPolicies () {
  const queryParams = {}

  queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)

  const options = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.installationPackages)
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        console.error('Something went wrong when Get Installation Packages ', res)
        return Promise.reject(res)
      }
      if (res.data &&
        res.data.installation_packages) {
        console.debug('Get Intallation Packages results: ', res)
        return Promise.resolve(res.data.installation_packages)
      }
      console.error('Something went wrong', res)
      return Promise.reject(res)
    })
    .catch(err => { return Promise.reject(err) })
}

/* PATCH Ticket Batch to edit a policy (pre-approved <=> unapproved)
Input: @param ticketSelected => all selected tickets
       @param insurancePolicyId => new policy id */
export function patchInstallationPackages (policies) {
  const body = {
    policies : [policies]
  }

  const options = {
    method  : 'PATCH',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : {
      state_token : localStorage.getItem(permissions.perms.state_token)
    },
    data : body,
    url  : (baseUrl.idp.endpoint + apiPaths.installationPackagesPatch)
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        console.error('PATCH Installation packages Batch error ', res)
        return Promise.reject(res)
      }
      if (res.data && res.data.installation_packages
      ) {
        console.debug('Policy edited')
        return Promise.resolve(res)
      }
      console.warn('Something went wrong during PATCH Installation packages Batch', res)
      return Promise.reject(res)
    })
    .catch(err => { return Promise.reject(err) })
}

export function patchTicketBatch (ticketToPatch) {
  const body    = {
    tickets : ticketToPatch
  }
  const options = {
    method  : 'PATCH',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : {
      state_token : localStorage.getItem(permissions.perms.state_token)
    },
    data : body,
    url  : (baseUrl.idp.endpoint + apiPaths.ticketBatch)
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        console.error('PATCH Ticket Batch installer error ', res)
        return Promise.reject(res)
      }
      if (res.data && res.data.success
      ) {
        console.debug('Ticket patched')
        return Promise.resolve(res)
      }
      console.warn('Something went wrong during PATCH Ticket Batch installer', res)
      return Promise.reject(res)
    })
    .catch(err => { return Promise.reject(err) })
}

export function getLoginPage () {
  return (baseUrl.idp.endpoint + apiPaths.webauthLogin)
}

/* Logout from the IWD */
export function logout () {
  const options = {
    method  : 'POST',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    data : {
      state_token   : localStorage.getItem(permissions.perms.state_token),
      refresh_token : localStorage.getItem(permissions.perms.refresh_token)
    },
    url : (baseUrl.idp.endpoint + apiPaths.webauthLogout)
  }

  return axios(options)
    .then(response => {
      if (response &&
      response.status &&
      response.status !== 200) {
        return Promise.reject(response)
      }
      console.debug('Logout from IWD')
      /* Clear all permissions stored */
      localStorage.clear()
      return Promise.resolve()
    }).catch(err => {
      console.error('Logout error', err.response)
      return Promise.reject(err.response)
    })
}

export function getUserData () {
  const queryParams = {}

  queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)

  const queryOptions = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.userRole)
  }

  return axios(queryOptions)
    .then(res => {
      if (res && res.status !== 200) {
        console.debug('Something went wrong when get users role')
        return Promise.reject(res)
      }
      if (res.data && res.data.ticket_roles) {
        return Promise.resolve({
          ticket_roles : res.data.ticket_roles
        })
      }
      return Promise.reject('Something went wrong, missings res.ticket_roles ' + res)
    })
    .catch(err => { console.error('Error during get users role: ', err); return Promise.reject(err.response) })
}

/* Revoke/Delete permissions of a specific user
Input: @params user => user object */
export function deleteUserRole (user) {
  const queryParams = {}

  queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)
  if (user.insurance_id) {
    queryParams.insuranceId = user.insurance_id
  }

  const options = {
    method  : 'DELETE',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.userDeleteRole).replace('{userId}', user.user_id)
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 204) {
        console.error('Delete user role error ', res)
        return Promise.reject(res)
      }
      console.debug('User deleted', res)
      return Promise.resolve(res)
    })
    .catch(err => { return Promise.reject(err) })
}

/* Get user by email */
export function getUserFromEmail (email) {
  const queryParams = {
    state_token : localStorage.getItem(permissions.perms.state_token),
    email       : email.toLowerCase()
  }

  const queryOptions = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.profile)
  }

  return axios(queryOptions)
    .then(res => {
      if (res && res.status !== 200) {
        console.debug('Something went wrong when get user ')
        return Promise.reject('Something went wrong when get user ' + res)
      }
      if (res.data) {
        return Promise.resolve(res.data)
      }
      return Promise.reject('Something wen wrong, missings res.data ' + res)
    })
    .catch(err => { return Promise.reject(err.response) })
}

/* Add new user (i.e. new installer or grohe_admin or...)
Input: @param body => body of the request (new user) */
export function addNewUser (body) {
  const options = {
    method  : 'POST',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    data   : body,
    params : {
      state_token : localStorage.getItem(permissions.perms.state_token)
    },
    url : (baseUrl.idp.endpoint + apiPaths.userRole)
  }

  return axios(options)
    .then(response => {
      if (response &&
        response.status &&
        response.status !== 201) {
        return Promise.reject(response)
      }
      console.debug('New user added ', response)
      return Promise.resolve()
    }).catch(err => {
      console.error('Add new user error', err)
      return Promise.reject(err)
    })
}

/* Get ticket statistics */
export function getStatistics (queryParams) {
  queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)

  const options = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.statistics)
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        console.error('Something went wrong when Get Statistics ', res)
        return Promise.reject(res)
      }
      if (res.data) {
        console.debug('Get Statistics: ', res.data)
        return Promise.resolve(res.data)
      }
      console.error('Something went wrong', res)
      return Promise.reject(res)
    })
    .catch(err => { return Promise.reject(err) })
}

/* Manual validation for a ticket (Checked status) */
export function getTicketValidationStatus (ticketId) {
  const queryParams = {}

  queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)

  const options = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.ticketValidation.replace('{ticketId}', ticketId))
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        console.error('Something went wrong during ticket validation ', res)
        return Promise.reject(res)
      }
      if (res.data) {
        console.debug('Ticket validation: ', res)
        return Promise.resolve(res.data)
      }
      console.error('Something went wrong', res)
      return Promise.reject(res)
    })
    .catch(err => { return Promise.reject(err) })
}

/* Return correct status filter by role */
export function getTicketStatus () {
  const queryParams = {}

  queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)

  const options = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    params : queryParams,
    url    : (baseUrl.idp.endpoint + apiPaths.ticketStatus)
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        console.error('Something went wrong during get status ', res)
        return Promise.reject(res)
      }
      if (res.data &&
        res.data.status) {
        return Promise.resolve(res.data.status)
      }
      console.error('Something went wrong', res)
      return Promise.reject(res)
    })
    .catch(err => { return Promise.reject(err) })
}

/* Get all devices settings for all insured locations of an user
*** No state token like query param, but perhaps is needed in the future ***/
export function getDeviceSettingsByUser (userId) {
  // const queryParams = {}

  // queryParams[permissions.perms.state_token] = localStorage.getItem(permissions.perms.state_token)

  const options = {
    method  : 'GET',
    headers : {
      'content-type' : 'application/json',
      Authorization  : 'Bearer ' + (localStorage.getItem(permissions.perms.access_token))
    },
    // params : queryParams,
    url : (baseUrl.idp.endpoint + apiPaths.deviceSettingsDashboard.replace('{userID}', userId))
  }

  return axios(options)
    .then(res => {
      if (res &&
        res.status &&
        res.status !== 200) {
        console.error('Something went wrong during get devices settings ', res)
        return Promise.reject(res)
      }
      if (res.data) {
        console.debug('Devices settings: ', res)
        return Promise.resolve(res.data)
      }
      console.error('Something went wrong', res)
      return Promise.reject(res)
    })
    .catch(err => { return Promise.reject(err) })
}
