/* eslint-disable no-multi-spaces */
import React from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'react-bootstrap/Image'
import groheImg from '../../../../Assets/img/grohe-logo.png'
import ReactDOM from 'react-dom'
import strings from '../../../strings/Strings'
import { getLoginPage } from '../../../../APICall/call'

/* Main GROHE Dashboard login widget */

export default function Login (props) {
  const useStyles = makeStyles(theme => ({
    paper : {
      marginTop     : theme.spacing(8),
      display       : 'flex',
      flexDirection : 'column',
      alignItems    : 'center',
      padding       : '3rem',
      border        : '3px solid',
      borderColor   : '#F5F5F5',
      boxShadow     : '0px 0px 27px -6px rgba(0,0,0,0.75)'
    },
    avatar : {
      margin          : theme.spacing(1),
      backgroundColor : theme.palette.secondary.main
    },
    form : {
      width     : '100%', // Fix IE 11 issue.
      marginTop : theme.spacing(1)
    },
    submit : {
      margin          : theme.spacing(3, 0, 2),
      backgroundColor : '#112038'
    }
  }))

  const classes = useStyles()

  /* Redirect to Idp authentication page to sign in */

  function fetchUserCredential () {
    const loginURL = getLoginPage()
    ReactDOM.render(
      window.location.replace(loginURL),
      document.getElementById('root'))
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Image width="50%" height="50%" style={{ margin: '3rem', borderRadius: '1rem' }} src={groheImg} />
          <Typography component="h1" variant="h5">
            {strings.loginPage.signIn}
          </Typography>
          <form id="signin_button_form" className={classes.form} noValidate>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => { fetchUserCredential() }}
            >
              {strings.loginPage.signIn}
            </Button>

          </form>
        </div>

      </Container>
    </>
  )
}
