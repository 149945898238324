/* eslint-disable react/prop-types */
import React from 'react'
import Paper from '@material-ui/core/Paper'
import Statistics from '../Statistics'
import DataTable from '../DataTable'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import auth from '../../GlobalValues/AuthenticationRole'
import strings from '../../../strings/Strings'
import stylesGrid from '../../../Styles/TopBarStyles'
import permissions from '../../GlobalValues/PermissionKeys'

export default function DashboardSection (props) {
  const classes2 = stylesGrid.stylesGrid()

  const isStatisticsVisible = () => {
    if (localStorage.getItem(permissions.perms.role) === auth.auth.grohe ||
         localStorage.getItem(permissions.perms.role) === auth.auth.insurance) {
      return (
        <>
          <Grid item xs={12} style={{
            marginTop       : '2rem',
            marginBottom    : '2rem',
            backgroundColor : '#112038',
            minHeight       : '4rem',
            justifyContent  : 'center',
            borderRadius    : '2%'
          }}>
            <Paper style={{ minHeight: '4rem', backgroundImage: 'linear-gradient(90deg, rgba(17,32,56,1) 0%, rgba(17,32,56,0.908000700280112) 100%)' }}>
              <Typography variant="h4" style={{
                color          : 'white',
                margin         : 'auto',
                position       : 'relative',
                justifyContent : 'center',
                alignItems     : 'center',
                display        : 'flex',
                fontFamily     : 'url(../../Assets/fonts/LinotypeUnivers-630Bold.ttf)',
                minHeight      : '4rem'
              }}>
                {strings.groheView.statistics}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Statistics />
          </Grid>
        </>
      )
    } else {
      return (<></>)
    }
  }

  const callbackToDeviceSettingsView = (user) => {
    props.renderDeviceSettingsView(user)
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes2.paper}>
          <DataTable renderDeviceSettingsView={callbackToDeviceSettingsView}/>
        </Paper>
      </Grid>
      {
        isStatisticsVisible()
      }
    </>
  )
}
