/* eslint-disable react/display-name */
/* eslint-disable no-multi-spaces */
import strings from '../../strings/Strings'
import filters from '../GlobalValues/QueryParams'
import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import validator from 'validator'
// import validator from 'react-validation'
// import SimpleReactValidator from 'simple-react-validator'

/* Function that return columns of the table with related options
For more info looka at: https://github.com/gregnb/mui-datatables */

const isFiltersValid = []

export default function getColumns (filterList, statuses) {
  const columns = [
    {
      name    : strings.dataTableParams.ticketId,
      options : {
        filter     : false,
        sort       : false,
        filterList : filterList[0],
        display    : false
      },
      id : filters.filters.ticket_id
    },
    {
      name    : strings.dataTableParams.insurancePolicyId,
      type    : filters.types.string,
      options : {
        filter        : true,
        sort          : false,
        filterList    : filterList[1],
        filterType    : 'custom',
        filterOptions :
        {
          display : (filterList, onChange, index, column) => {
            return getCustomTextFieldFilter(filterList, onChange, index, column)
          }
        }
      },
      id : filters.filters.insurance_policy_id
    },
    {
      name    : strings.dataTableParams.insuranceName,
      type    : filters.types.string,
      options : {
        filter        : true,
        sort          : false,
        filterList    : filterList[2],
        filterType    : 'custom',
        filterOptions :
        {
          display : (filterList, onChange, index, column) => {
            return getCustomTextFieldFilter(filterList, onChange, index, column)
          }
        }
      },
      id : filters.filters.insurance_name
    },
    {
      name    : strings.dataTableParams.insuranceId,
      type    : filters.types.string,
      options : {
        filter        : true,
        sort          : false,
        filterList    : filterList[3],
        filterType    : 'custom',
        filterOptions :
        {
          display : (filterList, onChange, index, column) => {
            return getCustomTextFieldFilter(filterList, onChange, index, column)
          }
        }
      },
      id : filters.filters.insurance_id
    },
    {
      name    : strings.groheView.zipCode,
      type    : filters.types.postalcode,
      options : {
        filter        : true,
        sort          : false,
        filterList    : filterList[4],
        filterType    : 'custom',
        filterOptions :
        {
          display : (filterList, onChange, index, column) => {
            return getCustomTextFieldFilter(filterList, onChange, index, column)
          }
        }
      },
      id : filters.filters.location_zipcode
    },
    {
      name    : strings.groheView.city,
      type    : filters.types.string,
      options : {
        filter        : false,
        sort          : false,
        filterList    : filterList[5],
        filterType    : 'custom',
        filterOptions :
        {
          display : (filterList, onChange, index, column) => {
            return getCustomTextFieldFilter(filterList, onChange, index, column)
          }
        }
      }
    },
    {
      name    : strings.dataTableParams.status,
      options : {
        filter                  : true,
        sort                    : false,
        filterList              : filterList[6],
        filterType              : 'dropdown',
        customFilterListOptions : { render: v => `Name: ${v}` },
        filterOptions           : {
          names : statuses
        }
      },
      id : filters.filters.status
    },
    {
      name    : '',
      options : {
        filter      : false,
        sort        : false,
        viewColumns : false,
        display     : true,
        filterList  : filterList[7]
      }
    },
    {
      name    : strings.dataTableParams.userEmail,
      type    : filters.types.email,
      options : {
        filter        : true,
        sort          : false,
        display       : false,
        viewColumns   : false,
        filterList    : filterList[8],
        filterType    : 'custom',
        filterOptions :
        {
          display : (filterList, onChange, index, column) => {
            return getCustomTextFieldFilter(filterList, onChange, index, column)
          }
        }
      },
      id : filters.filters.user_email
    },
    {
      name    : strings.dataTableParams.installerName,
      type    : filters.types.string,
      options : {
        filter        : true,
        sort          : false,
        display       : false,
        viewColumns   : false,
        filterList    : filterList[9],
        filterType    : 'custom',
        filterOptions :
        {
          display : (filterList, onChange, index, column) => {
            return getCustomTextFieldFilter(filterList, onChange, index, column)
          }
        }
      },
      id : filters.filters.installer_name
    },
    {
      name    : strings.dataTableParams.installerZipcode,
      type    : filters.types.postalcode,
      options : {
        filter        : true,
        sort          : false,
        display       : false,
        viewColumns   : false,
        filterList    : filterList[10],
        filterType    : 'custom',
        filterOptions :
        {
          display : (filterList, onChange, index, column) => {
            return getCustomTextFieldFilter(filterList, onChange, index, column)
          }
        }
      },
      id : filters.filters.installer_zipcode
    }
  ]

  return columns
}

/* Construct query params for the API Call */

export function getQueryParamsByColumns (filterList, page, pageSize) {
  const queryParams = {}
  getColumns(filterList).map((col, index) => {
    if (col.options.filter === true) {
      queryParams[col.id] = filterList[index] && filterList[index][0]
        ? filterList[index][0]
        : null
    }
  })
  queryParams.page      = page
  queryParams.page_size = pageSize

  return queryParams
}

/* Get custom text field components for filters,
with fields validation */
function getCustomTextFieldFilter (filterList, onChange, index, column) {
  const string      = filterList[index][0]
  const type        = getColumns(filterList)[index].type
  let isFilterValid = true
  if (filterList &&
    filterList[index] &&
    filterList[index][0] &&
    filterList[index][0] !== '') {
    switch (type) {
      case filters.types.email:
        isFilterValid = validator.isEmail(string)
        break
      case filters.types.postalcode:
        isFilterValid = validator.isPostalCode(string, 'any')
        break
      default:
        isFilterValid = true
    }
  }
  isFiltersValid[index] = isFilterValid
  return (
    <FormControl>
      <TextField
        label={isFilterValid === false
          ? <h4 style={{ color: 'red', margin: 0 }}>{strings.errors.invalid + ' ' + column.name +
          (type === filters.types.email
            ? ': ' + strings.errors.emailExactMatch
            : '')
          }</h4>
          : column.name}
        value={filterList[index][0] || ''}
        color={isFilterValid ? 'primary' : 'secondary'}
        onChange={event => {
          filterList[index][0] = event.target.value
          onChange(filterList[index], index, column)
        }}
        style={{ width: '100%', marginRight: '5%' }} />
    </FormControl>
  )
}
/* Check if there are invalid filters
Output: @param bool => Button Apply Filters: disabled = bool */

export function isNotValidFilters () {
  return isFiltersValid
    .filter((valid) => { return valid === false }).length > 0
}
