/* eslint-disable no-multi-spaces */
import LocalizedStrings from 'react-localization'

const string  = require('./translations.json')
const strings = new LocalizedStrings(string)
/* Array of available languages */
const langArray   = strings.getAvailableLanguages()
const langKey     = 'language'
const defLanguage = 'en'

const localStorageLang = localStorage.getItem(langKey) ? localStorage.getItem(langKey) : 'en'
localStorage.setItem(langKey, localStorageLang)

/* Check if the language of the browser is in our list && a language isn't set,
otherwise default will be forced to EN */
if ((localStorageLang &&
  langArray.indexOf(localStorageLang) === -1) ||
  langArray.indexOf(strings.getLanguage()) === -1) {
  localStorage.setItem(langKey, defLanguage)
}

strings.setLanguage(localStorageLang)

export default strings
