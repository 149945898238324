/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from '@material-ui/core'
import strings from '../../../strings/Strings'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import { patchTicketBatch } from '../../../../APICall/call'

/* Component responsible for setting the
"Approved || Rejected" status for the selected tickets  */

export default function ApproveTickets (props) {
  /* Method that set Approved the tickets [PATCH]
    Callback: boolean: canRefresh (Refresh Table) */

  const setApproved = () => {
    const ticketsToPatch = props.ticketSelected.map((ticket) => {
      return {
        ticket_id : ticket[0],
        status    : strings.status_type.approved.toLowerCase()
      }
    })
    patchTicketBatch(ticketsToPatch)
      .then(res => {
        if (res.data.failed &&
            res.data.failed.length > 0) {
          return Promise.reject(strings.errors.errorPatchTicket + res.data.failed)
        }
        props.canRefresh(true)
        return Promise.resolve()
      })
      .catch(err => {
        return alert(strings.errors.somethinWentWrong + (err.message || err.response || err.status || err))
      })
  }

  /* Method that set Rejected the tickets [PATCH]
    Callback: boolean: canRefresh (Refresh Table) */
  const setRejected = () => {
    const ticketsToPatch = props.ticketSelected.map((ticket) => {
      return {
        ticket_id : ticket[0],
        status    : strings.status_type.rejected.toLowerCase()
      }
    })
    patchTicketBatch(ticketsToPatch)
      .then(res => {
        if (res.data.failed &&
            res.data.failed.length > 0) {
          return Promise.reject(strings.errors.errorPatchTicket + res.data.failed)
        }
        props.canRefresh(true)
        return Promise.resolve()
      })
      .catch(err => {
        return alert(strings.errors.somethinWentWrong + (err.message || err.response || err.status || err))
      })
  }

  /* Method to check if is possible to change the status of a ticket
    (If approved i can't approve or reject otherwise if is open i can)
    Output: bool => Can not change status
    [! ( ! cannotChangeStatus) ] ==> !cannotChangeStatus == (undefined, null, ecc..) == I can(true) ==> disabled == ! (I can) = false */

  const cannotChangeStatus = () => {
    return props.ticketSelected
      .some(ticket => ticket[6].toLowerCase() !== strings.status_type.open.toLowerCase())
  }

  return (
    <>
      <Button
        disabled={cannotChangeStatus()}
        variant="outlined"
        color="primary"
        style={{ margin: '1rem' }}
        onClick={setApproved}
        startIcon={<ThumbUpIcon/>}>
        {strings.dataTableToolbarCustomStrings.approve}
      </Button>

      <Button
        disabled={cannotChangeStatus()}
        variant="outlined"
        color="secondary"
        style={{ margin: '1rem' }}
        onClick={setRejected}
        startIcon={<ThumbDownIcon/>}>
        {strings.dataTableToolbarCustomStrings.reject}
      </Button>
    </>
  )
}
