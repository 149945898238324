/* eslint-disable no-multi-spaces */
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import strings from '../../../strings/Strings'
import { Grid, Typography, IconButton } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import SearchIcon from 'material-react-icons/Search'
import SearchResult from '../AddUserModalComponent/SearchResult'
import SearchByEmailImg from '../../../../Assets/img/search_by_email.png'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CloseIcon from '@material-ui/icons/Close'
import { CircularProgress } from '@material-ui/core'
import { getUserFromEmail } from '../../../../APICall/call'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import validator from 'validator'


/* Component that represents the main modal box for finding the user by email
in the Permissions Management section. */

class AddUserModalBox extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      userResult: null,
      isLoading: false,
      open: false,
      searchDisabled: false,
      isEmail: true
    }
  }


  /* Method that takes care of getting the users presents (back-end)*/
  getUser = () => {
    this.setState({ isLoading: true })
    return getUserFromEmail(this.state.email)
      .then(res => {
        this.setState({ isLoading: false, userResult: res })
        return
      })
      .catch(err => {
        console.debug("ERROR: ", err.status)
        if (err.status === 404) {
          this.setState({ isLoading: false, userResult: '404' })
        }
        else {
          this.setState({ isLoading: false, userResult: null })
        }
        return
      })
  }

  handleOpen = () => {
    this.setState({
      open: true,
      isLoading: false,
      userResult: null,
      email: '',
      isEmail:true,
      searchDisabled:false
    })
  }

  /* OnChange Event that get the email typed */
  onChangeText = (e) => {
    this.setState({
      email: e.target.value,
      isEmail: validator.isEmail(e.target.value)
    })
  }

  /* Method that retun theSearchResult component
  Output: SearchResult component */

  renderResults = () => {
    return (<SearchResult results={this.state.userResult}
      refreshTable={() => {
        this.props.refreshTable();
        this.setState({ open: false })
      }} />)
  }

  render() {
    return (
      <>
        <Button
          startIcon={<PersonAddIcon />}
          variant="outlined" color="primary"
          onClick={() => { this.handleOpen() }}
          style={{ margin: '1rem' }}>
          {strings.adminView.addUser}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={() => { this.setState({ open: false }) }}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="draggable-dialog-title" style={{ backgroundColor: '#112038' }}>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4" style={{ color: 'white', fontFamily: 'url(../../../../Assets/fonts/LinotypeUnivers-630Bold.ttf)' }}>
                  {strings.adminView.addUser}
                </Typography>
              </Grid>
              <Grid item xs={1}>

                <IconButton style={{ color: '#FAFAFA' }}
                  onClick={() => { this.setState({ open: false }) }}><CloseIcon /></IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>

            <Grid container
              style={{ width: '100%' }}
              alignItems="center"
              justify="center">

              <Grid item xs={10}>
                <TextField
                  id="email"
                  label={this.state.isEmail || this.state.email === ''
                    ? strings.adminView.email
                    : <h4 style={{ color: 'red', margin: 0 }}>{strings.errors.emailExactMatch}</h4>}
                  type="text"
                  color={this.state.isEmail || this.state.email === '' ? 'primary' : 'secondary'}
                  style={{ padding: '0.5rem', marginBottom: '1rem', marginTop: '1rem', width: '100%' }}
                  variant="outlined"
                  value={this.state.email !== '' ? this.state.email : ''}
                  onChange={(e) => { this.onChangeText(e) }}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  disabled={!this.state.isEmail}
                  onClick={() => { this.getUser(this.state.email) }}
                >
                  <SearchIcon color="#112038" />
                </IconButton>
              </Grid>

              <Grid
                item xs={12}
                id="resultBox"
                style={{
                  minHeight: '10rem',
                  minWidth: '15rem',
                  padding: '0.5rem'
                }}>

                {this.state.isLoading
                  ? <CircularProgress size={24} style={{ position: 'relative', top: 4, float: "left" }} />
                  : this.state.userResult
                    ? this.renderResults()
                    : <VoidBoxHelp />}

              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}
export default AddUserModalBox

/* Void Box component (first open or void search) */
function VoidBoxHelp() {
  return (
    <Card>
      <CardContent style={{ backgroundColor: '#FAFAFA' }}>
        <img src={SearchByEmailImg}
          style={{
            maxWidth: '100%',
            maxHeight: '100%'
          }} />
      </CardContent>
    </Card>
  )
}
