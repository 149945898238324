/* eslint-disable react/prop-types */
/* eslint-disable no-multi-spaces */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import strings from '../../strings/Strings'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  container : {
    display   : 'flex',
    minHeight : '10rem',
    minWidth  : '10rem',
    flexWrap  : 'wrap'
  },
  formControl : {
    margin    : theme.spacing(1),
    minWidth  : '10rem',
    minHeight : '10rem'
  }
}))

/* Component that takes care of setting the "Appointment Made" status
by insert the date */

export default function AppointmentMadeAlert (props) {
  const classes         = useStyles()
  const [open, setOpen] = React.useState(false)
  const [date, setDate] = React.useState(new Date())

  const handleChange = event => {
    setDate(event.target.value)
  }

  /* Handle confirm button
    Output: @param date => return the date selected to the callback
    to the InstallerViewStatusUpdate */

  const handleConfirm = () => {
    props.dateCallback(date)
    setOpen(false)
  }

  return (
    <>
      <Button
        disabled={props.isDisabled}
        variant="outlined" color="primary" onClick={() => { setOpen(true) }}
        style={{ margin: '0.5rem' }}>
        {strings.dataTableToolbarCustomStrings.appointmentMade}
      </Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={() => { setOpen(false) }}>
        <DialogTitle>{strings.installerStatusUpdate.insertDate}</DialogTitle>
        <DialogContent>
          <TextField
            id="date"
            label={strings.installerStatusUpdate.appointmentDate}
            type="date"
            defaultValue={new Date().toISOString().split('T')[0]}
            className={classes.textField}
            InputLabelProps={{
              shrink : true
            }}
            onChange={event => handleChange(event)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpen(false) }} color="primary">
            {strings.groheView.cancel}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {strings.groheView.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
