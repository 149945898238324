/* eslint-disable react/prop-types */
import React from 'react'
import errImg from '../../Assets/img/wrong.png'
import Image from 'react-bootstrap/Image'
import strings from '../strings/Strings'

/* Function That shows an Error page
when some error occur or do some actions */
export default function ErrorPage (props) {
  const parseError = (response) => {
    const status = (response && response.status ? response.status : 'Unknown Error')
    switch (status) {
      case 401:
        props.login() // Callback to the login page
        return (<></>)
      default:
        return (
          <>
            <Image width={'100%'} height={'100%'} src={errImg} />
            <p><h1 style={{ color: '#767676' }}>{strings.errorPage.error + (response
              ? response.status || status
              : 'Error')}</h1></p>
            <p><h3 style={{ color: '#767676' }}>{response
              ? response.message || response.statusText
              : ''}</h3></p>
          </>
        )
    }
  }
  return (
    <>
      {parseError(props.response)}
    </>
  )
}
