import MUIDataTable from "mui-datatables"
import React, { Component } from 'react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import strings from '../../strings/Strings'
import InstallerModalBox from "../DataTableToolbarCustomComponents/GroheUser/InstallerModalBox"
import CsvExport from "../DataTableToolbarCustomComponents/AllUser/CsvExport"
import Button from '@material-ui/core/Button'
import CheckedTickets from "../DataTableToolbarCustomComponents/GroheUser/CheckedTickets"
import ExpandedRowDetailsView from './SubComponents/ExpandedRowDetailsView'
import TicketExtraInfoRow from "../DataTableBodyComponents/TicketExtraInfoRow"
import auth from '../../Home/GlobalValues/AuthenticationRole'
import getColumns from '../DataTableBodyComponents/Columns'
import InstallerViewStatusUpdate from '../DataTableToolbarCustomComponents/InstallerUser/InstallerViewStatusUpdate'
import ApproveTickets from "../DataTableToolbarCustomComponents/InsuranceUser/ApproveTickets"
import axios from 'axios'
import permissions from '../GlobalValues/PermissionKeys'
import { getTickets, getTicketStatus } from '../../../APICall/call'
import { CircularProgress } from '@material-ui/core'
import filters from '../GlobalValues/QueryParams'
import showError from '../../Errors/ErrorHandler'
import { getQueryParamsByColumns, isNotValidFilters } from '../DataTableBodyComponents/Columns'
import EditButton from '../DataTableToolbarCustomComponents/InsuranceUser/EditButton'
import styles from '../../Styles/responsiveRowOverride.css'
import RedirectToDevice from "../DataTableToolbarCustomComponents/GroheUser/RedirectToDevice"

const rowPageNumber = 15; //Numbers of rows per page
const rowsPerPageNumber = [15, 25, 50] //Array of numbers of rows per page allowed

axios.defaults.withCredentials = true


const muiDatatableTheme = createMuiTheme({
    overrides: {
        styles
    }
})

/* Class representing the table, with related options and data */
class DataTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowPageNumber: rowPageNumber,
            rowPerPage: rowsPerPageNumber,
            filterList: this.getDefaultFilterList(),                     // Contains the values ​​of the filters (Updated at each onFilterChange)

            /* selectedTickets == {
                page_number: {
                    indexes: [{}] => indexes of selected Tickets
                    tickets: [[ticket 1], [ticket 2], ...]
                }
            } */
            selectedTickets: [],
            page: 0,                                                     // Contains the current page
            count: 0,                                                    // Contains the count of total elements requested (virtual, not showed)
            tickets: [],                                                 // Contains the ticket data requested at the back ends
            filterListCached: [],                                        // Every apply filters, flush
            isLoading: false,
            isApplyFiltersDisabled: false,
            width: window.innerWidth,
            height: window.innerHeight,
            statuses: null
        }
    }


    /* Get initial state of filters list && insert the default filters */
    getDefaultFilterList = () => {
        let initialFilterListState = [[], [], [], [], [], [], [], [], [], [], []]
        return getColumns(initialFilterListState)
            .map((col) => {
                return col.id === filters.filters.status
                    ? [this.getDefaultStatusFilter()]
                    : []
            })
    }

    getDefaultStatusFilter = () => {
        switch (localStorage.getItem(permissions.perms.role)) {
            case auth.auth.insurance:
                return 'open'
            case auth.auth.installer:
                return 'assigned'
            case auth.auth.grohe:
                return 'approved'
        }
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({ isLoading: true })
        let queryParams = getQueryParamsByColumns(this.state.filterList, this.state.page, this.state.rowPageNumber)
        getTickets(queryParams)
            .then(res => {
                return Promise.resolve(this.updateTable(res))
            })
            .then(res => {
                return getTicketStatus()
            })
            .then(res => {
                this.setState({ statuses: res })
                return
            })
            .catch(err => { return Promise.reject(showError(err)) })
    }

    /* Front end change page on the table with a call to back-end for related data
    Input: New page */
    changePage = (page) => {
        this.setState({ page: page, isLoading: true })
        let queryParams = getQueryParamsByColumns(this.state.filterList, page, this.state.rowPageNumber)
        getTickets(queryParams)
            .then(res => { return Promise.resolve(this.updateTable(res)) })
            .catch(err => { return Promise.reject(showError(err)) })
    };

    /* Callback to the Dashboard to render device settings view 
    populated with the email */
    redirectToDeviceView = (user) => {
        this.props.renderDeviceSettingsView(user)
    }

    /* Method that updates the table with the new data requested
    Input: Table Data (JSONs) */

    updateTable = (tableData) => {
        /* Check if i am an insurer/grohe_admin and then insert Edit/checked/RedirectToDevice Button to the ticket */
        if (localStorage.getItem(permissions.perms.role) === auth.auth.insurance ||
            localStorage.getItem(permissions.perms.role) === auth.auth.grohe) {
            tableData.tickets
                .map((ticket) => {
                    switch (localStorage.getItem(permissions.perms.role)) {
                        case auth.auth.insurance:
                            if (ticket[6].toLowerCase() === strings.status_type.open.toLowerCase()) {
                                ticket.push(<EditButton thisTicket={ticket} canRefresh={this.refreshTableData} />)
                            }
                            break;
                        case auth.auth.grohe:
                            switch (ticket[6].toLowerCase()) {
                                case strings.status_type.installed.toLowerCase():
                                    ticket.push(<CheckedTickets thisTicket={ticket} canRefresh={this.refreshTableData} />)
                                    break
                                case strings.status_type.checked.toLowerCase():
                                    ticket.push(<RedirectToDevice ticketId={ticket[0]} redirectToDevice={this.redirectToDeviceView} />)
                                    break
                            }
                            break
                    }
                }
                )
        }

        this.setState({
            tickets: tableData.tickets,
            count: tableData.totalNumberOfRecords,
            page: tableData.currentPage,
            isLoading: false
        })

    }

    /* Call fresh data from the back end
    Input: Boolean canRefresh (Call new data from backend) */
    refreshTableData = (canRefresh) => {
        if (canRefresh) {
            console.log("refresh")
            let queryParams = getQueryParamsByColumns(this.state.filterList, this.state.page, this.state.rowPageNumber)
            this.setState({ isLoading: true, selectedTickets: [] })
            getTickets(queryParams)
                .then(res => { return Promise.resolve(this.updateTable(res)) })
                .catch(err => { return Promise.reject(showError(err)) })

        }
    }

    /* take the complete ticket data according to the ticket ID 
    Input: Ticket ID
    Output: More Info Sub-Row of the ticket*/

    getSelectedTickets = () => {
        let ticketArray = []
        for (const ticket of Object.values(this.state.selectedTickets)) {
            ticketArray.push(...ticket.tickets)
        }
        return ticketArray
    }

    getExtraData = (ticketId) => {
        return (<TicketExtraInfoRow ticketId={ticketId} />)
    }

    /* Returns the tickets selected on the current page 
    Output: return a list of selected tickets in the current page of the table*/
    getSelectedTicketsInCurrentPage = () => {
        if (this.state.selectedTickets &&
            this.state.selectedTickets[this.state.page] &&
            this.state.selectedTickets[this.state.page].indexes) {
            return this.state.selectedTickets[this.state.page].indexes
                .map((selIndex) => { return selIndex.index })
        }
        return -1
    }

    /* Filter table data */
    filterTable = () => {
        this.setState({ isLoading: true, selectedTickets: [] })
        let queryParams = getQueryParamsByColumns(this.state.filterList, 0, this.state.rowPageNumber)
        getTickets(queryParams)
            .then(res => { return Promise.resolve(this.updateTable(res)) })
            .catch(err => { return Promise.reject(showError(err)) })
    }

    /* Show Correct actions by Role */
    getCustomToolbarActionsByRole = () => {
        switch (localStorage.getItem(permissions.perms.role)) {
            case auth.auth.grohe:
                return (
                    <>
                        <Grid container>
                            {/* <Grid item xl={6}>
                                <CheckedTickets ticketSelected={this.getSelectedTickets()} canRefresh={this.refreshTableData} />
                            </Grid> */}
                            <Grid item xl={6}>
                                <InstallerModalBox ticketSelected={this.getSelectedTickets()} canRefresh={this.refreshTableData} />
                            </Grid>
                        </Grid>
                    </>
                )
            case auth.auth.installer:
                return (<>
                    <Grid container>
                        <InstallerViewStatusUpdate ticketSelected={this.getSelectedTickets()} canRefresh={this.refreshTableData} />
                    </Grid>
                </>)
            case auth.auth.insurance:
                return (
                    <>
                        <ApproveTickets ticketSelected={this.getSelectedTickets()} canRefresh={this.refreshTableData} />
                    </>)
        }
    }

    /* Build correct JSX Components to add to the table toolbar
     in function of the user role (i.e. Insurer have also the Edit insurance Policy component)
     (DEPRECATED) 
     
     => Add XLS/CSV Export compoenents to the table toolbar*/
    getCustomToolbarByRole = () => {
        return (<CsvExport filters={this.state.filterList} />)

        /* This could be usefull if EditInsurancePolicy is needed, at this moment isn't needed
        switch (localStorage.getItem(permissions.perms.role)) {
            case auth.auth.insurance:
                return (
                    <>
                        <CsvExport filters={this.state.filterList} />
                        <EditInsurancePolicy canRefresh={this.refreshTableData} />
                    </>
                )
            default:
                return (
                    <>
                        <CsvExport filters={this.state.filterList} />
                    </>
                )
        }
        */
    }

    /* To understand events and related I/O
     See: https://github.com/gregnb/mui-datatables */

    render() {
        /* Data Table Options */
        const textLabels = {
            body: {
                noMatch: strings.muiDataTableStrings.body.noMatch,
                toolTip: strings.muiDataTableStrings.body.toolTip,
            },
            selectedRows: {
                text: strings.muiDataTableStrings.selectedRows.text,
            },
            viewColumns: {
                title: strings.muiDataTableStrings.viewColumns.showColumns,
                titleAria: strings.muiDataTableStrings.viewColumns.titleAria,
            },
            filter: {
                all: strings.muiDataTableStrings.filter.all,
                title: strings.muiDataTableStrings.filter.title,
                reset: strings.muiDataTableStrings.filter.reset,
            },
        }

        const options = {
            download: false,
            print: false,
            search: false,
            serverSide: true,
            rowsPerPage: this.state.rowPageNumber,
            rowsPerPageOptions: this.state.rowPerPage,
            count: this.state.count,
            page: this.state.page,
            rowsSelected: this.getSelectedTicketsInCurrentPage(),
            expandableRows: true,
            textLabels: textLabels,
            filterType: 'textField',

            renderExpandableRow: (rowData, rowMeta) => {
                return (
                    <ExpandedRowDetailsView
                        extraData={this.getExtraData(rowData[0])}
                        rowDataLength={rowData.length} />)
            },
            /* Filter change event */
            onFilterChange: (column, filterLists, type) => {
                this.setState({ filterList: filterLists, isApplyFiltersDisabled: isNotValidFilters() })
            },
            /* Change rows per page event */
            onChangeRowsPerPage: (numberOfRows) => {
                this.setState({ rowPageNumber: numberOfRows, isLoading: true, page: 0 })
                let queryParams = getQueryParamsByColumns(this.state.filterList, 0, numberOfRows)
                getTickets(queryParams)
                    .then(res => { return Promise.resolve(this.updateTable(res)) })
                    .catch(err => { return Promise.reject(showError(err)) })
            },
            /* Custom footer in the Filters Popup */
            customFilterDialogFooter: filterList => {
                return (
                    <div style={{ marginTop: '4rem' }}>
                        <Button variant="outlined"
                            disabled={this.state.isApplyFiltersDisabled}
                            color="primary"
                            onClick={this.filterTable} >{strings.filters.apply}</Button>
                        <p><em>{strings.filterView.applyFilters}</em></p>
                    </div>
                );
            },

            /* Table change (i.e. data changes) event */
            onTableChange: (action, tableState) => {
                switch (action) {
                    case 'changePage':
                        this.changePage(tableState.page);
                        break;
                    case 'rowsSelect':
                        let selTickets = this.state.selectedTickets
                        selTickets[this.state.page] = {
                            indexes: tableState.selectedRows.data,
                            tickets: tableState.selectedRows.data
                                .map((selTicket) => { return this.state.tickets[selTicket.index] })
                        }
                        this.setState({ selectedTickets: selTickets })
                        break;
                    default:
                        break;
                }
            },

            /* Extra Buttons to the table after ticket selection 
            (i.e. Checked/Assign installer etc.. In APICall ni function of the user role) */
            customToolbarSelect: () => {
                return (
                    <div>
                        {this.getCustomToolbarActionsByRole()}
                    </div>
                );
            },

            customToolbar: () => {
                return (this.getCustomToolbarByRole())
            }
        };


        return (
            <Grid container>
                <Grid item xs={12}>
                    <MuiThemeProvider theme={muiDatatableTheme}>
                        <MUIDataTable
                            title={this.state.isLoading
                                && <CircularProgress size={24} style={{ position: 'relative', top: 4, float: "left" }} />}
                            data={this.state.tickets}
                            columns={getColumns(this.state.filterList, this.state.statuses)}
                            options={options}
                            responsive={"scroll"}
                        />
                    </MuiThemeProvider>
                </Grid>
            </Grid>
        );
    }
}

export default DataTable